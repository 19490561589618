import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    data?: any;
    value?: string;
};

// EmailTemplateRenderer Component
export const EmailTemplateRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const { _id, email_template_id, email_template_name } = data;
    const link = `/email_template_editor/${email_template_id}/${_id}`;
    
    return (
        <Row>

            {/* Name */}
            <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <Text size="0">{email_template_name}</Text>
            </Row>

            {/* Link */}
            <Row paddingLeft="0.5" paddingRight="0.5" grow="1" justifycontent="end">
                <Link to={link} target="_blank" style={{display: 'flex'}}>
                    <Icon name="open" size={18} color="gray" />
                </Link>
            </Row>
        </Row>
    )
};