import { createSlice } from '@reduxjs/toolkit';
import { MapSliceType } from './Map.slice.types';

const initialState: MapSliceType = {
    center: { lat: 39.381266, lng: -97.922211 },
    zoom: 4,
    maxZoom: 19,
    markers: []
};

export const MapSlice = createSlice({
    name: 'Map',
    initialState,
    reducers: {

        // Set Map Data Action
        setMapDataAction: (state, action) => {
            const { center, zoom, maxZoom, markers } = action.payload;

            state.center = {...center};
            state.zoom = zoom;
            state.maxZoom = maxZoom;
            state.markers = [...markers];
        },

        // Reset Map Action
        resetMapAction: (state) => {
            return {...initialState};
        }
    }
});

export const {
    setMapDataAction,
    resetMapAction
} = MapSlice.actions;
export default MapSlice.reducer;