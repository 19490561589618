import { FC } from 'react';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    data?: any;
    value?: string | boolean;
};

// BoolRenderer Component
export const BoolRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const boolValue = (value === 'true' || value === true) ? true : false;

    return (
        <Row shrink="1" justifycontent="center" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <Text size="2" color={boolValue ? 'green' : 'red'}>
                {
                    boolValue ?
                    <span>✓</span> :
                    <span>✗</span>
                }
            </Text>
        </Row>
    )
};