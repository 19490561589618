import { FC, memo, forwardRef, useState, useImperativeHandle, useEffect, useRef } from 'react';
import { SuppressKeyboardEventParams } from 'ag-grid-community';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { Dropdown, OptionType } from '../../../../Simple/Form/Dropdown/Dropdown.com';

type Props = ICellEditorParams;

// DropdownCellEditor Component
export const DropdownCellEditor: FC<Props> = memo(forwardRef((props: Props, ref) => {
    
    // Props
    const { value: initialValue, stopEditing, colDef = {} } = props;
    const { dropdownConfig = {}, name } = colDef as any;
    const { autocomplete, apiUrl, labelKey = 'name', valueKey = 'value', searchKey = 'name' } = dropdownConfig;

    // States
    const [value, setValue] = useState(initialValue);
    const [isValueSelected, setValueSelected] = useState(false);

    // Refs
    const originalSuppressKeyboardEvent = useRef(colDef.suppressKeyboardEvent);

    // Handle Change
    const handleChange = (name: string | string[], value: string) => {
        setValue(value);
        setValueSelected(true);
    };

    // Suppress Keyboard Event
    const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        const { event } = params;
        const { key } = event;

        if (key === 'Enter') {
            return true;
        }
        
        return false;
    };

    // Component Editor Lifecycle methods
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        }
    }, [value]);

    useEffect(() => {
        if (isValueSelected) {
            stopEditing(false);
        }
    }, [isValueSelected]);

    useEffect(() => {
        colDef.suppressKeyboardEvent = suppressKeyboardEvent;
        return () => {
            colDef.suppressKeyboardEvent = originalSuppressKeyboardEvent.current;
        }
    }, []);

    return (
        <Dropdown 
            name={name}
            autocomplete={autocomplete}
            apiUrl={apiUrl}
            labelKey={labelKey}
            valueKey={valueKey}
            searchKey={searchKey}
            onChange={handleChange}
            style={{ minHeight: '100%' }}
        />
    )
}));