import { FC, CSSProperties, useState, useEffect, RefObject, useRef } from 'react';
import classNames from '../../../../Utils/ClassNames';
import { Row } from '../../Layout/Flex/Row/Row.com';
import { Col } from '../../Layout/Flex/Col/Col.com';
import { Icon } from '../../Icon/Icon.com';
import css from './Input.module.scss';

type Props = {
    type?: 'text' | 'password' | 'email' | 'textarea' | 'number';
    name: string;
    value?: string;
    placeholder?: string;
    min?: number;
    max?: number;
    step?: number;
    onChange?: (name: string, value: string) => void;
    onClear?: () => void;
    required?: boolean;
    hideBorder?: boolean;
    innerRef?: RefObject<any>;
    style?: CSSProperties;
    className?: string;
};

// Input Component
export const Input: FC<Props> = (props: Props) => {
    const { type = 'text', name, value, placeholder, onChange, onClear, required = false, hideBorder = false, innerRef, style, className } = props;
    const { min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER, step = 1 } = props;

    // States
    const [inputValue, setInputValue] = useState<string | number>(value || '');
    const [isValid, setIsValid] = useState<boolean>(true);

    // Handle Invalid
    const handleInvalid = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (required === true) {
            e.preventDefault();
            setIsValid(false);
        }
    };

    // Round Value
    const roundValue = (value: number, step: number) => {
        const decimalPlaces = (step.toString().split('.')[1] || '').length;
        const factor = Math.pow(10, decimalPlaces);

        return Math.round(value * factor) / factor;
    }

    // Handle Change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const field = e.target as HTMLInputElement;
        let isValid = field.validity.valid;
        let { value } = field;

        // Fix for number input
        if (type === 'number') {
            console.log('value', value);
            
            value = value.replace(/[^0-9.]/g, '');
            value = roundValue(Number(value), step).toString();
            isValid = value !== '' && Number(value) >= min && Number(value) <= max;
        }
        
        setInputValue(value);
        required && setIsValid(isValid);
        onChange && onChange(name, value);
    };

    // On Clear
    const handleClear = () => {
        if (inputValue === '') return;

        setInputValue('');
        required && setIsValid(false);
        onChange && onChange(name, '');
        onClear && onClear();
    };

    // Increment
    const handleIncrement = () => {
        let newValue = Number(inputValue) + step <= max ? Number(inputValue) + step : max;
        newValue = roundValue(newValue, step);
        const isValid = newValue >= min && newValue <= max;

        setInputValue(newValue);
        required && setIsValid(isValid);
        onChange && onChange(name, newValue.toString());
    };

    // Decrement
    const handleDecrement = () => {
        let newValue = Number(inputValue) - step >= min ? Number(inputValue) - step : min;
        newValue = roundValue(newValue, step);
        const isValid = newValue >= min && newValue <= max;

        setInputValue(newValue);
        required && setIsValid(isValid);
        onChange && onChange(name, newValue.toString());
    };

    // Watch outside changes
    useEffect(() => {
        if (value !== undefined && value !== null && value !== inputValue) {
            setInputValue(value || '');
        }
    }, [value]);
    
    return (
        <Row className={classNames(css.InputWrapper, className)} grow="1">

            <Col grow="1" className={css.WrapperColumn}>
            
                {/* Input Text */}
                {
                    (
                        type !== 'text' &&
                        type !== 'password' &&
                        type !== 'email'
                    ) ? null :
                    <Row className={css.Input} data-invalid={!isValid} data-hideborder={hideBorder} grow="1">
                        <input
                            ref={innerRef} 
                            type={type}
                            name={name}
                            value={inputValue}
                            placeholder={placeholder}
                            required={required}
                            onInvalid={handleInvalid}
                            onChange={handleChange}
                            autoComplete="off"
                            className={css.InputElement} 
                            style={{...style}} 
                        />

                        {/* Clear Button */}
                        <Row paddingRight="0.5">
                            <Icon className={css.Clear} name="close" size={16} onClick={handleClear} data-active={inputValue !== ''} />
                        </Row>
                    </Row>
                }

                {/* Input Number */}
                {
                    type !== 'number' ? null :
                    <Row className={css.Input} data-invalid={!isValid} grow="1">
                        <input
                            ref={innerRef} 
                            type="text"
                            name={name}
                            value={inputValue}
                            min={min} 
                            max={max}
                            step={step}
                            placeholder={placeholder}
                            required={required}
                            onInvalid={handleInvalid}
                            onChange={handleChange}
                            autoComplete="off"
                            className={css.InputElement} 
                            style={{...style}} 
                        />

                        {/* Clear Button */}
                        <Row paddingRight="0.5">
                            <Icon className={css.Clear} name="close" size={16} onClick={handleClear} data-active={inputValue !== ''} />
                        </Row>

                        {/* Arrow */}
                        <Col className={css.NumberControls} paddingRight="0.25">

                            {/* Up */}
                            <Row className={css.Arrow} grow="1">
                                <Icon name="increment" size={16} onClick={handleIncrement} />
                            </Row>

                            {/* Down */}
                            <Row className={css.Arrow} grow="1">
                                <Icon name="decrement" size={16} onClick={handleDecrement} />
                            </Row>
                        </Col>
                    </Row>
                }

                {/* Textarea */}
                {
                    type !== 'textarea' ? null :
                    <Row className={css.Input} alignitems="start" data-invalid={!isValid} grow="1">
                        <textarea
                            ref={innerRef} 
                            name={name}
                            value={value || ''}
                            placeholder={placeholder}
                            required={required}
                            onInvalid={handleInvalid}
                            onChange={handleChange}
                            autoComplete="off"
                            className={classNames(css.InputElement, css.Textarea)} 
                            style={{...style, alignSelf: 'stretch'}} 
                        />

                        {/* Clear Button */}
                        <Row paddingRight="0.5" paddingTop="0.5">
                            <Icon className={css.Clear} name="close" size={16} onClick={handleClear} data-active={inputValue !== ''} />
                        </Row>
                    </Row>
                }

                {/* Error */}
                {
                    // required === false ? null : isValid ? null : <span className={css.Error}>Please fill out this field</span>
                }
            </Col>
        </Row>
    )
};