import { FC, useEffect, useState } from 'react';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { useAWV } from '../../../Hooks/AWV/AWV.hook';
import { useDownloadCSV } from '../../../Hooks/DownloadCSV/DownloadCSV.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';

// AWV Component
export const AWV: FC = () => {

    // Hooks
    const { requestAWVData } = useAWV();
    const { downloadCSV } = useDownloadCSV();

    // States
    const [title, setTitle] = useState('AWV');
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [columnModel, setColumnModel] = useState<any[]>([
        { field: 'patient_id', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'patient id', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]}
    ]);

    // Did Mount
    useEffect(() => {
        requestAWVData().then((data: any = {}) => {
            const { title, headers = [], rows = [], emr = '' } = data;
            const rowDataObjects = normalizeRows(headers, rows);

            title && setTitle(title);
            setHeaders(headers);
            setRows(rowDataObjects);

            // Update Column Model add emr
            setColumnModel([
                ...columnModel.map((model: any) => ({...model, emr}))
            ]);
        });
    }, []);

    return (
        <Col grow="1">
            
            {/* Page Title */}
            <PageTitle title={title} downloadCSV={() => downloadCSV(rows, title)} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};