import { FC, useEffect, useState } from 'react';
import { normalizeRows } from '../../../../Utils/NornalizeRowsData';
import { useDailyHuddleReports } from '../../../../Hooks/DailyHuddle/DailyHuddle.hook';
import { useAccounts } from '../../../../Hooks/Accounts/Accounts.hook';
import { useDownloadCSV } from '../../../../Hooks/DownloadCSV/DownloadCSV.hook';

import { PageTitle } from '../../PageTitle/PageTitle.com';
import { Col } from '../../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../../DataTable/DataTable.com';
import css from '../DailyHuddle.module.scss';

// DailyHuddleDischarge Component
export const DailyHuddleDischarge: FC = () => {

    // Hooks
    const { requestDailyHuddleDischargeData } = useDailyHuddleReports();
    const { selected: selectedAccount = '' } = useAccounts();
    const { downloadCSV } = useDownloadCSV();

    // States
    const [title, setTitle] = useState<string>('Daily Huddle Discharge');
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    const columnModel = [
        { field: 'full name', renderer: 'html'}
    ];

    // Did Mount
    useEffect(() => {
        requestDailyHuddleDischargeData().then((data: any = {}) => {
            const { title = '', headers = [], rows = [] } = data;
            const rowDataObjects = normalizeRows(headers, rows);

            setTitle(selectedAccount + ' - ' + title);
            setHeaders(headers);
            setRows(rowDataObjects);
        });
    }, []);

    return (
        <Col className={css.DailyHuddle} grow="1">
            
            {/* Page Title */}
            <PageTitle title={title} downloadCSV={() => downloadCSV(rows, title)} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};