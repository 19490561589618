import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';
import { Icon } from '../../Simple/Icon/Icon.com';

type Props = {
    value: string;
    colDef?: any;
    data?: any;
};

// AnswersLinkRenderer Component
export const AnswersLinkRenderer: FC<Props> = (props: Props) => {
    const { value, colDef = {}, data = {} } = props;
    const { _id } = data;

    // Handle Download
    const handleDownload = () => {
        const url = `https://labx.lavaa.health/get_pdf?subscription_id=${_id}`;

        fetch(url)
        .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'Answers.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(error => console.error('There has been a problem with your fetch operation:', error));
    };
    
    return (
        <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <Row>
                <Text size="1">
                    <Link to={`/query/queryName=answers_to_survey__${_id}`} target="_blank">{value || 0}</Link>
                </Text>
            </Row>
            <Row paddingLeft='0.75'>
                <Icon name="download" onClick={() => handleDownload()} />
            </Row>
        </Row>
    )
};