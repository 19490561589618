export const normalizeRows = (headers: string[], rows: [][]) => {
    return rows.map((row: any[]) => {
        let obj: {[key: string]: any} = {};

        row.forEach((value, index) => {
            // if (typeof value === 'string' && value.includes('<a')) {
            //     value = value.replace(/\\"/g, '"');
            // }
            obj[headers[index]] = value;
        });

        return obj;
    })
};