import { FC, ReactNode } from 'react';
import css from './Button.module.scss';

type Props = {
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    children: ReactNode;
};

// Button Component
export const Button: FC<Props> = (props: Props) => {
    const { type = 'button', onClick, children } = props;

    // Handle Click
    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <button className={css.Button} onClick={handleClick} type={type}>{children}</button>
    )
};