import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slices/Auth/Auth.slice';
import accountsReducer from './Slices/Accounts/Accounts.slice';
import mapReducer from './Slices/Map/Map.slice';
import queriesReducer from './Slices/Queries/Queries.slice';

export default configureStore({
    reducer: {
        auth: authReducer,
        accounts: accountsReducer,
        map: mapReducer,
        queries: queriesReducer
    }
});
