import { FC, useEffect, useState } from 'react';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { useCodesOptimization } from '../../../Hooks/CodesOptimization/CodesOptimization.hook';
import { useDownloadCSV } from '../../../Hooks/DownloadCSV/DownloadCSV.hook';

import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import { comparator, monetaryComparator } from '../DataTable/Comparator';
import { hasContent } from '../../../Utils/HasContent';

// CodesOptimization Component
export const CodesOptimization: FC = () => {

    // Hooks
    const { requestCodesOptimizationData } = useCodesOptimization();
    const { downloadCSV } = useDownloadCSV();

    // States
    const [title, setTitle] = useState('Codes Optimization');
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [columnModel, setColumnModel] = useState<any[]>([
        { field: 'patient id', minWidth: 180, renderer: 'html', comparator, actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'gaps', renderer: 'html', comparator},
        { field: 'suggestions', renderer: 'html', comparator},
        { field: 'billable codes', renderer: 'html', comparator},
        { field: '$', comparator: monetaryComparator},
    ]);

    // Did Mount
    useEffect(() => {
        requestCodesOptimizationData().then((data: any = {}) => {
            const { title, headers, rows, emr = '' } = data;
            const rowDataObjects = normalizeRows(headers, rows);
            const re = /<a.*?>(.*?)<\/a>/;

            // Fix url
            rowDataObjects.forEach((row: any) => {
                row['gaps'] = hasContent(re, row['gaps']) ? row['gaps'].replace('billing_details?', 'billing_details/') : '';
                row['suggestions'] = hasContent(re, row['suggestions']) ? row['suggestions'].replace('billing_details?', 'billing_details/') : '';
                row['billable codes'] = hasContent(re, row['billable codes']) ? row['billable codes'].replace('billing_details?', 'billing_details/') : '';
            });
            
            setTitle(title);
            setHeaders(headers);
            setRows(rowDataObjects);

            // Update Column Model add emr
            setColumnModel([
                ...columnModel.map((model: any) => ({...model, emr}))
            ]);
        });
    }, []);

    return (
        <Col grow="1">
            
            {/* Page Title */}
            <PageTitle title={title} downloadCSV={() => downloadCSV(rows, title)} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};