import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccounts } from '../../../Hooks/Accounts/Accounts.hook';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import { Text } from '../../Simple/Text/Text.com';
import css from './PageTitle.module.scss';

type Props = {
    title: string;
    downloadCSV?: () => void;
    onAction?: () => void;
};

// PageTitle Component
export const PageTitle: FC<Props> = (props: Props) => {

    const { title, downloadCSV, onAction } = props;
    const navigate = useNavigate();
    const { selected: selectedAccount = '' } = useAccounts();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Row className={css.PageTitle} paddingTop="1" paddingBottom="1">

            {/* Back */}
            {
                !selectedAccount ? null :
                <Row className={css.BackBtn} onClick={handleGoBack} paddingLeft="1" alignitems="center">
                    <Icon name="back" />
                </Row>
            }

            {/* Name */}
            <Row paddingLeft="0.5" alignitems="center">
                <Text size="4">{title}</Text>
            </Row>

            {/* Download CSV */}
            {
                !props.downloadCSV ? null :
                <Row grow="1" aligncontent="end" justifycontent="end">
                    <Row className={css.DownloadBtn} onClick={downloadCSV} paddingRight="1" alignitems="center">
                        <Icon name="download" />
                    </Row>
                </Row>
            }

            {/* Action */}
            {
                !onAction ? null : 
                <Row grow="1" aligncontent="end" justifycontent="end">
                    <Row className={css.DownloadBtn} paddingRight="1" alignitems="center">
                        <Icon name="click" onClick={onAction} />
                    </Row>
                </Row>
            }
        </Row>
    )
};