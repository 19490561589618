import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AccountsHookState, AccountsHookResult } from './UseAccounts.hook.types';
import { setAccountsDataAction, setSelectedAccountAction, resetAccountAction } from '../../Redux/Slices/Accounts/Accounts.slice';
import { API_URL } from '../../Constants/Constants';

export const useAccounts = (): AccountsHookResult => {
    const keys = useSelector((state: {accounts: AccountsHookState}) => state.accounts.keys);
    const data = useSelector((state: {accounts: AccountsHookState}) => state.accounts.data);
    const selected = useSelector((state: {accounts: AccountsHookState}) => state.accounts.selected);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Request Accounts Data
    const requestAccountsData = () => {
        fetch(`${API_URL}/accounts/get_accounts`, {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((result) => {
            if (result) {
                dispatch(setAccountsDataAction(result));
            }
        });
    };

    // Request Dashboard Account Header
    const requestDashboardAccountHeader = (account: string): Promise<string> => {
        return fetch(`${API_URL}/accounts/get_account_dashboard_header?account_name=${account}`, {
            credentials: 'include'
        })
        .then((response) => response.text())
    };

    // Request Account Dashboard Chart Data
    const requestAccountDashboardChartData = (account: string): Promise<any> => {
        return fetch(`${API_URL}/accounts/get_account_dashboard_chart_data?account_name=${account}`, {
            credentials: 'include'
        })
        .then((response) => response.json())
    };

    // Request Account Details
    const requestAccountDetails = (account: string): Promise<any> => {
        return fetch(`${API_URL}/accounts/get_account_details?account_name=${account}`, {
            credentials: 'include'
        })
        .then((response) => response.text())
    };

    // Select Account
    const selectAccount = (account: string) => {
        if (account) {
            fetch(`${API_URL}/accounts/select_account?account_name=${account}`, {
                credentials: 'include'
            })
            .then((response) => response.text())
            .then((result) => {
                if (result) {
                    dispatch(setSelectedAccountAction(result));
                    navigate('/');
                }
            });
        }
    };

    // Reset
    const reset = () => {
        dispatch(resetAccountAction());
    };

    return {
        keys,
        data,
        selected,
        requestAccountsData,
        requestDashboardAccountHeader,
        requestAccountDashboardChartData,
        requestAccountDetails,
        selectAccount,
        reset
    };
};