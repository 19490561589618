import { useParams } from 'react-router-dom';
import { QueryHookResult } from './Query.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useQuery = (): QueryHookResult => {

    // Hooks
    const { details } = useParams();

    // Request Query Data
    const requestQueryData = () => {
        const params = new URLSearchParams(details);
        const queryName = params.get('queryName');
        const eventType = params.get('eventType');
        const daysBack = params.get('daysBack');
        const body: any = {};

        // Set Body Params
        queryName && (body['query_name'] = queryName);
        eventType && (body['event_type'] = eventType);
        daysBack && (body['days_back'] = daysBack);
        
        // Request Query Data
        return requestPost(`${API_URL}/queries/get_query`, {
            body: JSON.stringify(body)
        });
    };

    return {
        requestQueryData
    };
};