import { DailyHuddleHookResult } from './DailyHuddle.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useDailyHuddleReports = (): DailyHuddleHookResult => {

    // Request Query
    const requestQuery = (queryName: string): Promise<any> => {
        return requestPost(`${API_URL}/queries/get_query`, {
            body: JSON.stringify({
                query_name: queryName
            })
        });
    };

    // Request Daily Huddle High Worry Score Data
    const requestDailyHuddleHighWorryScoreData = () => {
        return requestQuery('get_daily_huddle_high_worry_score_no_near_appointment');
    };

    // Request Daily Huddle Long Overdue Medium Worry Score Data
    const requestDailyHuddleLongOverdueMediumWorryScoreData = () => {
        return requestQuery('get_daily_huddle_long_overdue_medium_worry_score');
    };

    // Request Daily Huddle Discharge Data
    const requestDailyHuddleDischargeData = () => {
        return requestQuery('get_daily_huddle_discharge');
    };

    // Request No AWV This Year Data
    const requestNoAWVThisYearData = () => {
        return requestQuery('get_daily_huddle_no_awv_this_year_high_worry_score');
    };

    // Request No AWV Last Year Data
    const requestNoAWVLastYearData = () => {
        return requestQuery('get_daily_huddle_no_awv_last_year');
    };

    return {
        requestDailyHuddleHighWorryScoreData,
        requestDailyHuddleLongOverdueMediumWorryScoreData,
        requestDailyHuddleDischargeData,
        requestNoAWVThisYearData,
        requestNoAWVLastYearData
    };
};