import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSubscriptions } from '../../../Hooks/Subscriptions/Subscriptions.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import { RecipientRenderer } from './RecipientRenderer.com';
import { EmailTemplateRenderer } from './EmailTemplateRenderer.com';
import { TemplateNameRenderer } from './TemplateNameRenderer.com';
import { ResendRenderer } from './ResendRenderer.com';
import { DateRenderer } from './DateRenderer.com';
import { BoolRenderer } from './BoolRenderer.com';
import { API_URL } from '../../../Constants/Constants';
import { LinkRenderer } from './LinkRenderer.com';
import css from './Subscriptions.module.scss';
import { AnswersLinkRenderer } from './AnswersLinkRenderer.com';

// Subscriptions Component
export const Subscriptions: FC = () => {
    
    // Hooks
    const { id } = useParams();
    const { requestSubscriptionsData, addRow, updateRow, deleteRow } = useSubscriptions();

    // States
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    const columnModel = [
        { field: 'client', editable: true, minWidth: 130 }, 
        { field: 'email', minWidth: 170}, 
        { field: 'type_url', headerName: 'Preview', renderer: LinkRenderer, minWidth: 120, floatingFilter: false, suppressMenu: true},
        { field: 'recipient_id', editable: true, renderer: RecipientRenderer, minWidth: 170}, 
        { 
            field: 'email_template_name', headerName: 'Email template', editable: true, editorType: 'select', 
            projectField: 'email_template',
            dropdownConfig: { 
                autocomplete: true, 
                apiUrl: `${API_URL}/email_templates/get_templates_autocomplete`,
                labelKey: 'name',
                valueKey: '_id',
                searchKey: 'template_name'
            }, 
            renderer: EmailTemplateRenderer, minWidth: 170, filter: () => 'asd'
        }, 
        { field: 'active', renderer: BoolRenderer, minWidth: 100}, 
        { field: 'name', editable: true, renderer: TemplateNameRenderer, minWidth: 160}, 
        { field: 'interval', editable: true, minWidth: 100}, 
        { field: 'interval_units', editable: true}, 
        { field: 'type'}, 
        { field: 'version', editable: true, editorType: 'number', step: 0.1, min: 0.1, max: 100, minWidth: 100},
        { field: 'last_sent', minWidth: 130, renderer: DateRenderer}, 
        { field: 'Last Opened', minWidth: 130, renderer: DateRenderer}, 
        { field: 'subscribe', minWidth: 120, renderer: BoolRenderer}, 
        { field: 'unsubscribed_date', minWidth: 120, renderer: DateRenderer}, 
        { field: 'resend', renderer: ResendRenderer, minWidth: 90, floatingFilter: false, suppressMenu: true},
        { field: 'answers_count', headerName: 'Answers', renderer: AnswersLinkRenderer, minWidth: 120, floatingFilter: true, suppressMenu: true},
    ];

    const formData = [
        { name: 'client', type: 'text', value: '', required: true, placeholder: 'Client', label: 'Client' },
        { name: 'recipient_id', type: 'text', value: '', required: true, placeholder: 'Patient Id', label: 'Patient Id' },
        { 
            name: 'email_template', type: 'select', value: '', required: true, 
            placeholder: 'Email Template', label: 'Email Template',
            dropDownConfig: {
                autocomplete: true, 
                apiUrl: `${API_URL}/email_templates/get_templates_autocomplete`,
                labelKey: 'name',
                valueKey: '_id',
                searchKey: 'template_name'
            }
        },
        { name: 'email', type: 'email', value: '', required: true, placeholder: 'Email address', label: 'Email address' },
        { name: 'name', type: 'text', value: '', required: true, placeholder: 'Subscription name', label: 'Subscription name' },
        { name: 'interval', type: 'number', value: '', min: 1, max: 1000, step: 1, required: true, placeholder: 'Interval', label: 'Interval' },
        { 
            name: 'interval_units', type: 'select', value: '', required: true, 
            placeholder: 'Interval Units', label: 'Interval Units',
            dropDownConfig: {
                options: [
                    { name: 'Days', value: 'days' },
                    { name: 'Weeks', value: 'weeks' },
                    { name: 'Months', value: 'months' },
                    { name: 'Years', value: 'years' },
                ]
            }
        },
        { name: 'version', type: 'number', value: '', min: 0.1, max: 100, step: 0.1, required: true, placeholder: 'Version', label: 'Version' },
        { type: 'submit' },
    ];

    // On Data Table Changed
    const handleDataTableActions = (action: string, data: any) => {

        // Filter Changed
        if (action === 'filterChanged') {
            console.log('Filter Changed', data);
            const filter: any[] = Object.keys(data).map((key: string) => {
                const field = data[key];

                return {
                    filteredByField: key,
                    // filterType: filterTypes[field.type],
                    filterTerm: field.filter,
                    // filterFrom: null,
                    // filterTo: null
                };
            });
            console.log('Filter', filter);
        }

        // Add new row
        if (action === 'add') {
            addRow(data).then((data: any) => {
                setRows([...rows, data]);
            });
        }

        // Update row
        if (action === 'update') {
            updateRow(data).then((data: any) => {
                const { primary_key, update_data } = data;
                
                const updatedRows = rows.map(row => {
                    if (row[primary_key] === data[primary_key]) {
                        return update_data;
                    }
                    return row;
                });

                setRows(updatedRows);
            });
        }

        // Delete row
        if (action === 'delete') {
            deleteRow(data).then(({id}) => {
                if (id) {
                    const updatedRows = rows.filter(row => row._id !== id);
                    setRows(updatedRows);
                }
            });
        }
    };

    // Handle Send All
    const handleSendAll = async (data: any[]) => {
        const ids = data.map(row => row._id);

        try {
            const response = await fetch(`${API_URL}/subscriptions/sendall`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ids })
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const now = new Date();
                const formattedDate = `${now.getFullYear()}_${(now.getMonth() + 1).toString().padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}`;
                const formattedTime = `${now.getHours()}:${now.getMinutes()}`;
                const fileName = `Bulk_Send_${formattedDate}_${formattedTime}.csv`;

                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            } else {
                const errorData = await response.json();
                alert(errorData.message || 'Error downloading file');
            }
        } catch (error) {
            console.error('Request failed:', error);
            alert('Failed to download CSV file');
        }
    };

    // Did Mount
    useEffect(() => {
        requestSubscriptionsData(id).then((data: any[] = []) => {
            
            // Extend data with answer field
            // data = data.map((row: any) => ({...row, answer: row._id}));
            
            setHeaders(['client', 'email', 'type_url', 'answers_count', 'recipient_id', 'email_template_name', 'active', 'name', 'interval', 'interval_units', 'type', 'version', 'last_sent', 'Last Opened', 'subscribe', 'unsubscribed_date', 'resend']);
            setRows(data);
        });
    }, []);

    return (
        <Col className={css.Subscriptions} grow="1">
            
            {/* Page Title */}
            <PageTitle title="Subscriptions" />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    onAction={handleDataTableActions} 
                    deleteAction={true}
                    columnModel={columnModel}
                    formData={formData}
                    formDataClassName={css.FormData}
                    formDataWidth="50vw"
                    sendAll={true}
                    handleSendAll={handleSendAll}
                    // formDataHeight="75vh"
                />
            </Col>
        </Col>
    )
};