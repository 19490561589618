import { FC, useEffect, useState } from 'react';
import { useQuery } from '../../../Hooks/Query/Query.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { useDownloadCSV } from '../../../Hooks/DownloadCSV/DownloadCSV.hook';
import css from './Query.module.scss';

type Props = {};

// Query Component
export const Query: FC<Props> = (props: Props) => {

    // Hooks
    const { requestQueryData } = useQuery();
    const { downloadCSV } = useDownloadCSV();

    // States
    const [title, setTitle] = useState('Queries');
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [columnModel, setColumnModel] = useState<any[]>([
        { field: 'patient_id', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'patient id', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'patent id', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'patientid', minWidth: 180, renderer: 'html', actionButtons: [{icon: 'hms', action: 'openHms'}, {icon: 'elation', action: 'openElation'}, {icon: 'copy', action: 'copy'}]},
        { field: 'full name', renderer: 'html'},
        { field: 'patienturl', renderer: 'html'}
    ]);
    
    // On Data Table Actions
    const handleDataTableActions = (eventType: string, data: any) => {
        console.log(eventType, data);
    };
    
    // Did Mount
    useEffect(() => {
        requestQueryData().then((data: any = {}) => {
            const { title, headers = [], rows = [], emr = '' } = data;
            const rowDataObjects = normalizeRows(headers, rows);

            title && setTitle(title);
            setHeaders(headers);
            setRows(rowDataObjects);

            // Update Column Model add emr
            setColumnModel([
                ...columnModel.map((model: any) => ({...model, emr}))
            ]);
        });
    }, []);
    
    return (
        <Col className={css.Query} grow="1">

            {/* Page Title */}
            <PageTitle title={title} downloadCSV={() => downloadCSV(rows, title)} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable 
                    headers={headers} 
                    rows={rows} 
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};