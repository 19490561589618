import { FC, CSSProperties, useState, useRef, useEffect, Fragment } from 'react';
import classNames from '../../../../Utils/ClassNames';
import { Col } from '../../Layout/Flex/Col/Col.com';
import { Row } from '../../Layout/Flex/Row/Row.com';
import { Text } from '../../Text/Text.com';
import { Icon } from '../../Icon/Icon.com';
import { Input } from '../Input/Input.com';
import { Options } from './Options.com';
import { requestPost } from '../../../../Utils/Request';
import css from './Dropdown.module.scss';

export type OptionType = (string | Record<string, string>) & {selected?: boolean};

type DropdownProps = {
    name: string;
    options?: OptionType[];
    onChange: (name: string | string[], value: string) => void;
    multiSelect?: boolean;
    autocomplete?: boolean;
    apiUrl?: string;
    labelKey?: string;
    valueKey?: string;
    searchKey?: string;
    placeholder?: string;
    required?: boolean;
    style?: CSSProperties;
    className?: string;
};

// Dropdown Component
export const Dropdown: FC<DropdownProps> = (props: DropdownProps) => {

    // Props
    const { name, onChange, multiSelect = false, autocomplete = false, apiUrl } = props;
    const { labelKey = 'name', valueKey = 'value', searchKey = 'name', placeholder = 'Search...', required = false, style, className } = props;

    // Refs
    const dropdownRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<any>(null);

    // States
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<string>('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<OptionType[]>([]);
    const [isValid, setIsValid] = useState<boolean>(true);

    // Get Option Value
    function getOptionValue(option: OptionType): string {
        if (option.hasOwnProperty(valueKey) === false) {
            throw new Error(`${valueKey} is not a property of options, please check valueKey="${valueKey}"`);
        }
        return typeof option === 'string' ? option : option[valueKey];
    }

    // Get Option Label
    function getOptionLabel(option: OptionType): string {
        if (option.hasOwnProperty(labelKey) === false) {
            throw new Error(`${labelKey} is not a property of options, please check labelKey="${labelKey}"`);
        }
        return typeof option === 'string' ? option : option[labelKey];
    }

    // Handle Toggle
    const handleToggle = () => setIsOpen(!isOpen);

    // Handle Select
    const handleSelect = (option: OptionType) => {
        const label = getOptionLabel(option);
        const value = getOptionValue(option);
        
        // Autocomplete update options, mark it as selected
        if (multiSelect === true) {
            setOptions((state: any) => {
                const updatedState = state.map((option: any) => {
                    if (option[valueKey] === value) {
                        return {
                            ...option,
                            selected: !option.selected
                        };
                    }
                    return option;
                });
                return updatedState;
            });
        }
        
        autocomplete && setInputValue(label);
        setSelected(label);
        multiSelect === false && setIsOpen(false);
        required && setIsValid(true);
        onChange(name, value);
    };

    // Handle Input Change
    const handleInputChange = (name: string, value: string) => {
        if (autocomplete === true) {
            if (!isOpen) setIsOpen(true);
            if (value !== '') setOptions([]);
            setInputValue(value);
        }
    };

    // Handle Clear
    const handleClear = () => {
        inputRef.current && inputRef.current.focus();
        setIsOpen(false);
        required && setIsValid(false);
    };

    // Handle Invalid
    const handleInvalid = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (required === true) {
            e.preventDefault();
            required && setIsValid(false);
        }
    };

    // Fetch Options
    const fetchOptions = () => {
        if (apiUrl) {
            requestPost(apiUrl, { 
                body: JSON.stringify({[searchKey]: inputValue})
            }).then((data: any = []) => {
                setOptions(data);
            });
        }
    };

    // Fetch Options when autocomplete is true
    useEffect(() => {
        if (autocomplete === true && apiUrl && inputValue) {
            fetchOptions();
        }
    }, [autocomplete, apiUrl, inputValue]);

    // Fetch Options when autocomplete is false
    useEffect(() => {
        if (autocomplete === false && apiUrl) {
            fetchOptions();
        }
    }, []);

    // Handle Click Outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) &&
                (optionsRef.current && !optionsRef.current.contains(event.target as Node))
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setOptions(props.options || []);
    }, [props.options]);

    // Filter Options
    // const activeOptions = apiUrl ? fetchedOptions : options;
    const filteredOptions = options.filter((option: OptionType) => {
        const label = getOptionLabel(option).toLowerCase();
        const value = getOptionValue(option).toLowerCase();
        
        return label.includes(inputValue.toLowerCase()) || value.includes(inputValue.toLowerCase());
    });

    return (
        <Row innerRef={dropdownRef} grow="1" className={classNames(css.Dropdown, className)} style={{...style}}>

            <Col grow="1" style={{height: '100%'}}>
                
                {/* Autocomplete Input */}
                {
                    autocomplete === false ? null :
                    <Row className={css.Input} alignitems="center" grow="1" data-invalid={!isValid}>
                        <Input
                            hideBorder={true}
                            className={css.InputElement} 
                            innerRef={inputRef}
                            type="text" 
                            name="dropdownInput" 
                            value={inputValue} 
                            onChange={handleInputChange} 
                            placeholder={placeholder} 
                            onClear={handleClear}
                        />
                    </Row>
                }

                {/* Selected */}
                {
                    autocomplete === true ? null :
                    <Row className={css.Selected} onClick={handleToggle} alignitems="center" data-invalid={!isValid}>
                        <Row grow="1">
                            
                            {/* Placeholder */}
                            {
                                selected !== '' ? null :
                                <Text size="1" color="gray">{placeholder}</Text>
                            }

                            {/* Selected */}
                            {
                                multiSelect === true ? null : selected === '' ? null :
                                <Text size="1">{selected}</Text>
                            }

                            {/* Selected Multiple */}
                            {
                                multiSelect === false ? null : selected === '' ? null :
                                filteredOptions.map((option: OptionType, index: number) => (
                                    <Fragment key={'option_'+index}>
                                        {
                                            !option.selected ? null :
                                            <Text size="1">
                                                {getOptionLabel(option)}
                                                {
                                                    index === filteredOptions.length-1 ? '' : ', '
                                                }
                                            </Text>
                                        }
                                    </Fragment>
                                ))
                            }
                        </Row>
                        <Row className={css.Arrow}>
                            <Icon name="arrowDown" />
                        </Row>
                    </Row>
                }

                {/* Options */}
                {
                    isOpen === false ? null :
                    <Options innerRef={optionsRef} dropdownRef={dropdownRef} data={filteredOptions} onSelect={handleSelect} multiSelect={multiSelect} />
                }

                {/* Hidden Input */}
                {
                    required === false ? null :
                    <input style={{height: 0, width: 0, border: 'none', visibility: 'hidden'}} type="text" defaultValue={selected} required={required} onInvalid={handleInvalid} />
                }
            </Col>
        </Row>
    )
};