import { FC, useEffect, useState } from 'react';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { useIDEReports } from '../../../Hooks/IDEReports/IDEReports.hook';
import { useAccounts } from '../../../Hooks/Accounts/Accounts.hook';
import { useDownloadCSV } from '../../../Hooks/DownloadCSV/DownloadCSV.hook';

import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import css from './IDEReports.module.scss';

// IDEReports Component
export const IDEReports: FC = () => {

    // Hooks
    const { requestIDEReportsData } = useIDEReports();
    const { selected: selectedAccount = '' } = useAccounts();
    const { downloadCSV } = useDownloadCSV();

    // States
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    const columnModel = [
        { field: 'project_name', renderer: 'html'},
        { field: 'run_date'}
    ];

    const title = selectedAccount + ' - IDE Reports';

    // Did Mount
    useEffect(() => {
        requestIDEReportsData().then((data: any = {}) => {
            const { headers = [], rows = [] } = data;

            setHeaders(headers);
            setRows(rows);
        });
    }, []);

    return (
        <Col className={css.IDEReports} grow="1">
            
            {/* Page Title */}
            <PageTitle title={title} downloadCSV={() => downloadCSV(rows, title)} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};