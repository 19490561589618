// Comparator
export const comparator = (valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: boolean) => {
    const isEmptyA = valueA === '';
    const isEmptyB = valueB === '';

    if (isEmptyA && !isEmptyB) {
        return isInverted ? -1 : 1; // In descending sort, empty strings at top; in ascending, at bottom
    }
    if (!isEmptyA && isEmptyB) {
        return isInverted ? 1 : -1; // In descending sort, empty strings at top; in ascending, at bottom
    }
    if (isEmptyA && isEmptyB) {
        return 0; // Both strings are empty, order doesn't matter
    }

    // Comparing non-empty strings
    return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
};

// Monetary comparator
export const monetaryComparator = (valueA: string, valueB: string) => {
    const numberA = parseFloat(valueA.replace(/[$,]/g, ''));
    const numberB = parseFloat(valueB.replace(/[$,]/g, ''));

    return numberA - numberB;
};