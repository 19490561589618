import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    data?: any;
    value?: string;
};

// RecipientRenderer Component
export const RecipientRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const { recipient_id_url } = data;

    return (
        <Row>

            {/* Name */}
            <Row style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <Text size="0">{value}</Text>
            </Row>

            {/* Link */}
            <Row paddingLeft="0.5" paddingRight="0.5" grow="1" justifycontent="end">
                <Link to={recipient_id_url} target="_blank" style={{display: 'flex'}}>
                    <Icon name="open" size={18} color="gray" />
                </Link>
            </Row>
        </Row>
    )
};