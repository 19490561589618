import { FC, useEffect, useState } from 'react';
import { useSubscriptionTemplates } from '../../../Hooks/SubscriptionTemplates/SubscriptionTemplates.hook';
import { useEmailTemplates } from '../../../Hooks/EmailTemplates/EmailTemplates.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import { API_URL } from '../../../Constants/Constants';
import css from './SubscriptionTemplates.module.scss';

// SubscriptionTemplates Component
export const SubscriptionTemplates: FC = () => {

    // Hooks
    const { requestSubscriptionTemplatesData, addRow, updateRow, deleteRow } = useSubscriptionTemplates();
    const { requestEmailTemplatesData } = useEmailTemplates();

    // States
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [emailTemplates, setEmailTemplates] = useState<any[]>([]);

    const columnModel = [
        { field: '_id'},
        { field: 'name', editable: false},
        { field: 'templates', editable: false, renderer: 'list', renderField: 'name', filter: false},
        { field: 'subscribers', editable: false, renderer: 'html', filter: false}
    ];

    const [formData, setFormData] = useState([
        { name: 'name', type: 'text', value: '', required: true, placeholder: 'Name', label: 'Name' },
        { 
            name: 'templates', type: 'select', value: '', required: true, 
            placeholder: 'Templates', label: 'Templates',
            dropDownConfig: {
                // autocomplete: true, 
                multiSelect: true,
                // apiUrl: `${API_URL}/email_templates/get_templates_autocomplete`,
                options: emailTemplates,
                labelKey: 'name',
                valueKey: '_id',
                // searchKey: 'template_name'
            }
        },
        { type: 'submit', style: {alignSelf: 'flex-end'} }
    ]);

    // On Data Table Changed
    const handleDataTableActions = (action: string, data: any) => {

        // Add new row
        if (action === 'add') {
            addRow(data).then((data: any) => {
                setRows([...rows, data]);
            });
        }

        // Update row
        if (action === 'update') {
            updateRow(data).then((data: any) => {
                const { primary_key, update_data } = data;

                const updatedRows = rows.map(row => {
                    if (row[primary_key] === data[primary_key]) {
                        return update_data;
                    }
                    return row;
                });
                
                setRows(updatedRows);
            });
        }

        // Delete row
        if (action === 'delete') {
            deleteRow(data).then(({id}) => {
                const updatedRows = rows.filter(row => row._id !== id);
                setRows(updatedRows);
            });
        }
    };

    // Did Mount
    useEffect(() => {

        // Request Subscription Templates Data
        requestSubscriptionTemplatesData().then((data: any[] = []) => {
            setHeaders(['_id', 'name', 'templates', 'subscribers']);

            // Add subscribers link
            data.forEach(row => {
                row.subscribers = `<a target="_blank" href="/subscriptions/${row._id}">Subscribers</a>`;
            });
            
            setRows(data);
        });

        // Request Email Templates Data
        requestEmailTemplatesData().then((data: any[] = []) => {
            setEmailTemplates(data);
            setFormData(state => {
                const updatedState = state.map((row: any) => {
                    row.name === 'templates' && (row.dropDownConfig.options = data);
                    return row;
                })
                return updatedState;
            });
        });
    }, []);

    return (
        <Col className={css.SubscriptionTemplates} grow="1">
            
            {/* Page Title */}
            <PageTitle title="Subscription Templates" />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    onAction={handleDataTableActions} 
                    // deleteAction={true}
                    columnModel={columnModel}
                    formData={formData}
                    formDataClassName={css.FormData}
                    formDataWidth="50vw"
                    formDataHeight="230px"
                />
            </Col>
        </Col>
    )
};