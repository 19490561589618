import { FC } from 'react';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';
import { Icon } from '../../Simple/Icon/Icon.com';

type Props = {
    data?: any;
    value?: string;
};

// DateRenderer Component
export const DateRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const date = new Date(value as string);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Месяцы начинаются с 0
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return (
        <Col>

            {/* Date */}
            <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <Icon name="calendar" size={16}/>
                <Row paddingLeft="0.25">
                    <Text size="0">{`${year}-${month}-${day}`}</Text>
                </Row>
            </Row>

            {/* Time */}
            <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <Icon name="time" size={16} />
                <Row paddingLeft="0.25">
                    <Text size="0">{`${hour}:${minute}`}</Text>
                </Row>
            </Row>
        </Col>
    )
};