import { FC } from 'react';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import css from './Footer.module.scss';

// Footer Component
export const Footer: FC = () => {

    return (
        <Row className={css.Footer}></Row>
    )
};