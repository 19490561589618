import { FC, useEffect } from 'react';
import { RoutesProvider } from './Components/Routes/RoutesProvider.com';

import { Text } from './Components/Simple/Text/Text.com';
import { Element } from './Components/Simple/Layout/Element/Element.com';
import { FlexElement } from './Components/Simple/Layout/Flex/FlexElement/FlexElement.com';
import { Row } from './Components/Simple/Layout/Flex/Row/Row.com';
import { Col } from './Components/Simple/Layout/Flex/Col/Col.com';
import { Grid } from './Components/Simple/Layout/Grid/Grid.com';
import './Theme.css';
import './App.css';
import { Header } from './Components/Complex/Header/Header.com';
import { useAuth } from './Hooks/UseAuth/UseAuth.hook';

const App: FC = () => {
  const { isAuth, check } = useAuth();

  // Check Auth State
  useEffect(() => {
    if (!isAuth) {
      check();
    }
  }, []);

  // Loader
  if (!isAuth) {
    // return null;
  }
  
  // App
  return <RoutesProvider />
}

export default App;
