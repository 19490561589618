import { useDispatch, useSelector } from 'react-redux';
import { AuthHookResult, AuthHookState } from './UseAuth.hook.types';
import { loginAction, logoutAction } from '../../Redux/Slices/Auth/Auth.slice';
import { setSelectedAccountAction } from '../../Redux/Slices/Accounts/Accounts.slice';
import { API_URL } from '../../Constants/Constants';

export const useAuth = (): AuthHookResult => {
    const isAuth = useSelector((state: { auth: AuthHookState }) => state.auth.isAuth);
    const username = useSelector((state: { auth: AuthHookState }) => state.auth.username);
    const dispatch = useDispatch();

    // Login
    const login = (username: string, password: string) => {
        if (username && password) {
            const formData = new FormData();
            
            formData.append('username', username);
            formData.append('password', password);

            fetch(`${API_URL}/auth/login`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
            })
            .then((response) => response.text())
            .then((result) => {
                if (result) {
                    dispatch(loginAction({name: result}));
                }
            });
        }
    };

    // Logout
    const logout = () => {
        return fetch(`${API_URL}/auth/logout`, {
            credentials: 'include'
        }).then(() => {
            dispatch(logoutAction());
        });
    };

    // Check
    const check = () => {
        fetch(`${API_URL}/auth/check`, {credentials: 'include'})
        .then((response) => response.json())
        .then((result) => {
            if (result) {
                const { username, account_name } = result;
                username && dispatch(loginAction({name: username}));
                account_name && dispatch(setSelectedAccountAction(account_name));
            }
        })
    };

    return {
        isAuth,
        username,
        login,
        logout,
        check
    };
};