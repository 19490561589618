import { FC } from 'react';
import classNames from '../../../../../Utils/ClassNames';
import { ColProps } from './Col.types';
import { FlexElement } from '../../Flex/FlexElement/FlexElement.com';
import css from './Col.module.scss';

type Props = ColProps;

// Col Component
export const Col: FC<Props> = (props: Props) => {
    const { style, className, children, ...rest } = props;

    return (
        <FlexElement 
            {...rest} 
            className={classNames(css.Col, className)} 
            style={{...style}}
        >{children}</FlexElement>
    )
};