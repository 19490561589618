import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    value: string;
    colDef?: any;
    data?: any;
};

// LinkRenderer Component
export const LinkRenderer: FC<Props> = (props: Props) => {
    const { value, colDef = {}, data = {} } = props;
    
    return (
        <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            <Text size="1">
                <Link to={value} target="_blank">{data.type}</Link>
            </Text>
        </Row>
    )
};