import { FC } from 'react';
import { DailyHuddleHighWorryScore } from './Tables/DailyHuddleHighWorryScore.com';
import { DailyHuddleLongOverdueMediumWorryScore } from './Tables/DailyHuddleLongOverdueMediumWorryScore.com';
import { DailyHuddleDischarge } from './Tables/DailyHuddleDischarge.com';
import { NoAWVThisYear } from './Tables/NoAWVThisYear.com';
import { NoAWVLastYear } from './Tables/NoAWVLastYear.com';
import { Grid } from '../../Simple/Layout/Grid/Grid.com';
import css from './DailyHuddle.module.scss';

// DailyHuddle Component
export const DailyHuddle: FC = () => {

    return (
        <Grid className={css.DailyHuddle} type="row" template="500px 500px 500px 500px 500px">
            <DailyHuddleHighWorryScore />
            <DailyHuddleLongOverdueMediumWorryScore />
            <DailyHuddleDischarge />
            <NoAWVThisYear />
            <NoAWVLastYear />
        </Grid>
    )
};