// Download CSV Hook
export const useDownloadCSV = () => {

    // Format Header
    const formatHeader = (header: string): string => {
        return header
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    // Extract Text From HTML
    const extractTextFromHTML = (htmlString: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
    };

    // Convert Data to CSV
    const convertToCSV = (data: any[]): any => {
        const headers = Object.keys(data[0]);
        const formattedHeaders = headers.map((header: string) => formatHeader(header));
        const headerString = formattedHeaders.join(',') + '\n';

        const dataString = data.map((row: any) => {
            return headers.map((header: string) => {
                const value = row[header];
                const text = extractTextFromHTML(value);
                const escaped = ('' + text).replace(/"/g, '""');
                return `"${escaped}"`;
            }).join(',');
        }).join('\n');
        return headerString + dataString;
    };

    // Download CSV
    const downloadCSV = (data: any[], filename: string = 'file_name') => {
        if (!data || data.length === 0) return;
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return {
        downloadCSV
    };
};