import { FC } from 'react';
import { Col } from '../../../../Simple/Layout/Flex/Col/Col.com';
import { Text } from '../../../../Simple/Text/Text.com';

type Props = {
    value: string;
};

// TextCellRenderer Component
export const TextCellRenderer: FC<Props> = (props: Props) => {
    const { value } = props;

    return (
        <Col>
            <Text size="0">{value}</Text>
        </Col>
    )
};