import { FC } from 'react';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import css from './Toolbar.module.scss';

type Props = {
    onSwitchMode?: () => void;
};

// Toolbar Component
export const Toolbar: FC<Props> = (props: Props) => {

    const { onSwitchMode } = props;

    return (
        <Row id="editor-toolbar" className={css.Toolbar}>

            {/* Font */}
            <select className="ql-font" defaultValue="arial" onChange={(e) => e.persist()}>
                <option value="arial">Arial</option>
                <option value="comic-sans">Comic Sans</option>
                <option value="courier-new">Courier New</option>
                <option value="georgia">Georgia</option>
                <option value="helvetica">Helvetica</option>
                <option value="lucida">Lucida</option>
            </select>
            
            {/* Headers */}
            <Row>
                <select className="ql-header" defaultValue={''} onChange={(e) => e.persist()}>
                    <option value="1"></option>
                    <option value="2"></option>
                </select>
            </Row>

            {/* Bold */}
            <Row>
                <button className="ql-bold"></button>
            </Row>
            
            {/* Italic */}
            <Row>
                <button className="ql-italic"></button>
            </Row>
            
            {/* Color */}
            <Row>
                <select className="ql-color" defaultValue={''}>
                    <option value="red"></option>
                    <option value="green"></option>
                    <option value="blue"></option>
                    <option value="orange"></option>
                    <option value="violet"></option>
                    <option value="#d0d1d2"></option>
                </select>
            </Row>

            {/* Insert Star */}
            <Row grow="1" justifycontent="end">
                <Icon name="html" onClick={onSwitchMode} size={24} />
            </Row>
        </Row>
    )
};