import { FC, CSSProperties } from 'react';
import classNames from '../../../Utils/ClassNames';
import { Row } from '../Layout/Flex/Row/Row.com';
import { Label } from './Label/Label.com';
import { Input } from './Input/Input.com';
import { HtmlEditor } from '../../Complex/HtmlEditor/HtmlEditor.com';
import { Dropdown } from './Dropdown/Dropdown.com';
import { Button } from '../Button/Button.com';
import css from './FormData.module.scss';

type Props = {
    config?: any[];
    onChange?: (name: string | any, value: string) => void;
    onSubmit?: () => void;
    style?: CSSProperties;
    className?: string;
};

// FormData Component
export const FormData: FC<Props> = (props: Props) => {
    const { config = [], onChange, onSubmit, style, className } = props;

    // Handle Input Change
    const handleInputChange = (name: string, value: string) => {
        onChange && onChange(name, value);
    };

    // Handle Submit
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit && onSubmit();
    };
    
    return (
        <form  
            className={classNames(css.FormData, className)}
            style={{...style}}
            onSubmit={handleSubmit}
        >
            {/* Form Data */}
            {
                config.map((item: any, index: number) => {
                    const alignItems = item.alignItems || 'center';
                    return <Row key={`form_row_${index}`} alignitems={alignItems} className={item.className} style={item.style}>
                    
                        {/* Label */}
                        {
                            !item.label ? null :
                            <Row grow="0" shrink="0" paddingRight="1" style={{flexBasis: '150px'}}>
                                <Label>{item.label}</Label>
                            </Row>
                        }

                        {/* Input Text */}
                        {
                            (
                                item.type !== 'text' &&
                                item.type !== 'password' &&
                                item.type !== 'email' &&
                                item.type !== 'textarea' &&
                                item.type !== 'hidden'
                            ) ? null :
                            <Row grow="1">
                                <Input 
                                    type={item.type} 
                                    name={item.name}
                                    value={item.value}
                                    required={item.required} 
                                    placeholder={item.placeholder}
                                    onChange={handleInputChange}
                                />
                            </Row>
                        }

                        {/* Input Number */}
                        {
                            (
                                item.type !== 'number'
                            ) ? null :
                            <Row grow="1">
                                <Input
                                    type={item.type} 
                                    name={item.name}
                                    value={item.value}
                                    min={item.min} 
                                    max={item.max}
                                    step={item.step}
                                    required={item.required} 
                                    placeholder={item.placeholder}
                                    onChange={handleInputChange}
                                />
                            </Row>
                        }

                        {/* Html Editor */}
                        {
                            item.type !== 'html' ? null :
                            <HtmlEditor name={item.name} value={item.value} onChange={handleInputChange} />
                        }

                        {/* Dropdown */}
                        {
                            (item.type !== 'select' && item.type !== 'dropdown') ? null :
                            <Row grow="1">
                                <Dropdown
                                    {...item.dropDownConfig}
                                    name={item.name}
                                    required={item.required}
                                    onChange={handleInputChange}
                                />
                            </Row>
                        }

                        {/* Submit */}
                        {
                            item.type !== 'submit' ? null :
                            <Row grow="1" justifycontent="end">
                                <Button type="submit">{item.text || 'Submit'}</Button>
                            </Row>
                        }
                    </Row>
                })
            }
        </form>
    )
};