import { FC, ReactNode } from 'react';
import { Grid } from '../../Simple/Layout/Grid/Grid.com';
import { Header } from '../Header/Header.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { Footer } from '../Footer/Footer.com';
import css from './AppLayout.module.scss';

type Props = {
    children: ReactNode;
};

// AppLayout Component
export const AppLayout: FC<Props> = (props: Props) => {
    const { children } = props;

    // const config = {
    //     '50px': {
    //         tpl: '80px 1fr 40px',
    //         gridColumn: 2 / 7,
    //         gridRow: 2 / 4,
    //     },
    //     50: {
    //         tpl: '50px 1fr 40px'
    //     }
    // };

    return (
        <Grid type="row" className={css.AppLayout} template="80px 1fr 40px" /*config={config}*/>

            {/* Header */}
            <Header />

            {/* Content */}
            <Col scroll={true}>
                {children}
            </Col>

            {/* Footer */}
            <Footer />
        </Grid>
    )
};