import { HomePageStructure } from "./HomePage.types";

export const structure: HomePageStructure[] = [
    {
        title: "Reports and Data analysis",
        cards: [
            {
                name: "Patients optimization",
                icon: "subscriptions",
                description: 'Patients optimization List - include all the patients that are both in AWV and the code optimization',
                link: "/patients_optimization"
            },
            {
                name: "Daily Huddle",
                icon: "huddle",
                description: "Daily Huddle",
                link: "/daily_huddle"
            },
            {
                name: "Dashboard",
                icon: "dashboard",
                description: "Dashboard",
                link: "/dashboard"
            },
            {
                name: "AWV",
                icon: "subscriptions",
                description: "AWV Description",
                link: "/awv"
            },
            {
                name: "Map",
                icon: "map",
                description: "Show data on map view",
                link: "/map"
            }
        ]
    },
    {
        title: "Pathways",
        cards: [
            // {
            //     name: "Forms",
            //     icon: "forms",
            //     description: "All available form surveys and the subscribers",
            //     link: "/forms"
            // },
            {
                name: "Subscribtion templates",
                icon: "subscribtionTemplates",
                description: "Subscribtion templates",
                link: "/subscription_templates"
            },
            {
                name: "Subscriptions",
                icon: "subscriptions",
                description: "All the system subscribers",
                link: "/subscriptions"
            },
            {
                name: "Email templates",
                icon: "mail",
                description: "All the email templates",
                link: "/email_templates"
            },
            {
                name: "Page templates",
                icon: "pageTemplates",
                description: "All the page templates",
                link: "/page_templates"
            },
            {
                name: "QRDA templates",
                icon: "pageTemplates",
                description: "QRDA templates",
                link: "/qrda_templates"
            }
        ]
    },
    {
        title: "Operational",
        cards: [
            {
                name: "Queries",
                icon: "queries",
                description: "All available queries for the care team",
                link: "/queries"
            },
            {
                name: "IDE Reports",
                icon: "reports",
                description: "Reports generated as output from IDE",
                link: "/ide_reports"
            },
            {
                name: "Codes optimization",
                icon: "subscriptions",
                description: "Codes optimization Description",
                link: "/codes_optimization"
            }
        ]
    }
];