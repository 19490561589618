import { ChartsHookResult } from './Charts.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useCharts = (): ChartsHookResult => {

    // Request Charts Data
    const requestChartsData = () => {
        
        // Request Charts Data
        return requestPost(`${API_URL}/charts/get_data`);
    };

    return {
        requestChartsData
    };
};