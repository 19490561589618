import { PatientsOptimizationHookResult } from './PatientsOptimization.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const usePatientsOptimization = (): PatientsOptimizationHookResult => {

    // Request PatientsOptimization Data
    const requestPatientsOptimizationData = () => {
        return requestPost(`${API_URL}/queries/get_query`, {
            body: JSON.stringify({
                query_name: 'patient_optimization',
                days_back: 'YTD'
            })
        });
    };

    return {
        requestPatientsOptimizationData
    };
};