import { CodesOptimizationHookResult } from './CodesOptimization.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useCodesOptimization = (): CodesOptimizationHookResult => {

    // Request CodesOptimization Data
    const requestCodesOptimizationData = () => {
        return requestPost(`${API_URL}/queries/get_query`, {
            body: JSON.stringify({
                query_name: 'search_missing_billing',
                days_back: 'YTD'
            })
        });
    };

    return {
        requestCodesOptimizationData
    };
};