import { FC, ReactNode, CSSProperties } from 'react';

type Props = {
    style?: CSSProperties;
    children: ReactNode;
};

// Label Component
export const Label: FC<Props> = (props: Props) => {
    const { style, children } = props;

    return (
        <label style={{...style}}>{children}</label>
    )
};