import { FC, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { useMap } from '../../../Hooks/Map/Map.hook';
import { MarkerType } from '../../../Redux/Slices/Map/Map.slice.types';

// Map Component
export const Map: FC = () => {

    const { requestMapData, center, zoom, maxZoom, markers } = useMap();

    // Did Mount
    useEffect(() => {
        requestMapData();
    }, []);

    return (
        <Col style={{width: '100%', height: '100%', backgroundColor: 'red'}}>
            <MapContainer center={[center.lat, center.lng]} maxZoom={maxZoom} zoom={zoom} scrollWheelZoom={true} style={{height: '100%'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    markers.map((marker: MarkerType, index: number) => {
                        return (
                            <Marker key={`marker_${index}`} position={[marker.lat, marker.lng]}>
                                <Tooltip permanent={true}>{marker.label}</Tooltip>
                            </Marker>
                        )
                    })
                }
            </MapContainer>
        </Col>
    )
};