import { FC } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, 
    BarChart as BarChrt, Bar, Rectangle
} from 'recharts';
import { getRandomColor } from '../../../../Utils/RandomColor';

type Props = {
    data?: any[];
    bars?: string[];
    width?: number;
    height?: number;
    xAxisKey?: string;
    tooltip?: boolean;
};

const CustomTick = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
          <title>{payload.value}</title>
          {payload.value.length > 10 ? `${payload.value.substring(0, 10)}...` : payload.value}
        </text>
      </g>
    );
  };

  const formatXAxis = (tickItem: any) => {
    return tickItem.length > 10 ? `${tickItem.substring(0, 10)}...` : tickItem;
}

// BarChart Component
export const BarChart: FC<Props> = (props: Props) => {

    // Props
    const { data = [], bars = [], width = 500, height = 300, xAxisKey, tooltip = true } = props;
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChrt
            //   width={500}
            //   height={300}
                data={data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {
                    xAxisKey === '' ? null : <XAxis dataKey={xAxisKey} angle={25} textAnchor="start" height={90} tick={<CustomTick />} />//tickFormatter={formatXAxis}
                }
                <YAxis />
                { tooltip === false ? null : <Tooltip />}
                <Legend />
                {
                    bars.map(({dataKey, color = getRandomColor()}: any) => (
                        <Bar 
                            key={`${dataKey}-bar`} 
                            dataKey={dataKey} 
                            fill={color} 
                            // activeBar={<Rectangle fill="pink" stroke="blue" />} 
                        />
                    ))
                }
            </BarChrt>
        </ResponsiveContainer>
    )
};