import { FC, useEffect, useState } from 'react';
import { usePageTemplates } from '../../../Hooks/PageTemplates/PageTemplates.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import css from './PageTemplates.module.scss';

// PageTemplates Component
export const PageTemplates: FC = () => {

    // Hooks
    const { requestPageTemplatesData, addRow, updateRow, deleteRow } = usePageTemplates();

    // States
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    const columnModel = [
        { field: '_id'},
        { field: 'name', editable: true},
        { field: 'client', editable: true},
        { field: 'title', editable: true},
        { field: 'html', editable: true, editorType: 'html'}
    ];

    const formData = [
        { name: 'name', type: 'text', value: '', required: true, placeholder: 'Name', label: 'Name' },
        { name: 'client', type: 'text', value: '', required: true, placeholder: 'Client', label: 'Client' },
        { name: 'title', type: 'text', value: '', required: true, placeholder: 'Title', label: 'Title', step: 0.1, min: 0.1, max: 100 },
        { name: 'html', type: 'html', value: '', required: false, placeholder: 'HTML', label: 'HTML', style: {alignItems: 'stretch'} },
        { type: 'submit' }
    ];

    // On Data Table Changed
    const handleDataTableActions = (action: string, data: any) => {

        // Add new row
        if (action === 'add') {
            addRow(data).then((data: any) => {
                setRows([...rows, data]);
            });
        }

        // Update row
        if (action === 'update') {
            updateRow(data).then((data: any) => {
                const { primary_key, update_data } = data;

                const updatedRows = rows.map(row => {
                    if (row[primary_key] === data[primary_key]) {
                        return update_data;
                    }
                    return row;
                });
                
                setRows(updatedRows);
            });
        }

        // Delete row
        if (action === 'delete') {
            deleteRow(data).then(({id}) => {
                const updatedRows = rows.filter(row => row._id !== id);
                setRows(updatedRows);
            });
        }
    };

    // Did Mount
    useEffect(() => {
        requestPageTemplatesData().then((data: any[] = []) => {
            setHeaders(['_id', 'name', 'client', 'title', 'html']);
            setRows(data);
        });
    }, []);

    return (
        <Col className={css.PageTemplates} grow="1">
            
            {/* Page Title */}
            <PageTitle title="Page Templates" />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows} 
                    onAction={handleDataTableActions} 
                    deleteAction={true}
                    columnModel={columnModel}
                    formData={formData}
                    formDataClassName={css.FormData}
                    formDataWidth="50vw"
                    formDataHeight="50vw"
                />
            </Col>
        </Col>
    )
};