import { createSlice } from '@reduxjs/toolkit';
import { AuthSliceType } from './Auth.slice.types';

const initialState: AuthSliceType = {
    isAuth: false,
    username: ''
};

export const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {

        // Login
        loginAction: (state, action) => {
            const { name } = action.payload;

            if (name && name.length > 0 && state.isAuth === false) {
                state.isAuth = true;
                state.username = name;
            }
        },

        // Logout
        logoutAction: (state) => {
            return {...initialState};
        },
    }
});

export const {
    loginAction,
    logoutAction
} = authSlice.actions;
export default authSlice.reducer;
