import { FC, ReactElement } from 'react';
import classNames from '../../../Utils/ClassNames';
import { icons, IconsTypes } from './Icons';
import css from './Icon.module.scss';

type Props = {
    name: IconsTypes;
    size?: number;
    color?: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
    className?: string;
};

// Icon Component
export const Icon: FC<Props> = (props: Props): ReactElement => {
    const { name, size = 24, color, onClick, className, ...rest } = props;
    const svg = icons[name];

    // Handle Click
    const handleClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        onClick && onClick(e);
    };
    
    return (
        <span 
            {...rest}
            className={classNames(css.Icon, className)} 
            dangerouslySetInnerHTML={{ __html: svg }} 
            style={{fontSize: size, color: color}} 
            onClick={handleClick}
            data-clickable={!!onClick}
        />
    )
};