import { IDEReportsHookResult } from './IDEReports.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useIDEReports = (): IDEReportsHookResult => {

    // Request IDEReports Data
    const requestIDEReportsData = () => {
        return requestPost(`${API_URL}/ide_reports/get_reports`);
    };

    return {
        requestIDEReportsData
    };
};