import { FC } from 'react';
import { HomePageStructure } from './HomePage.types';
import { Area } from './Area/Area.com';
import { structure } from './Structure';
import { AccountDashboardHeader } from '../AccountDashboardHeader/AccountDashboardHeader.com';

// HomePage Component
export const HomePage: FC = () => {
    return (
        <>

            {/* Account Header */}
            <AccountDashboardHeader />

            {/* Cards */}
            {
                structure.map(({title, cards}: HomePageStructure, index: number) => <Area key={title} title={title} cards={cards} />)
            }
        </>
    )
};