import { FC } from 'react';
import {  ResponsiveContainer, RadarChart as RadarChrt, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts';
import { getRandomColor } from '../../../../Utils/RandomColor';

type Props = {
    data?: any[];
    radarOptions?: any[];
    dataKey?: string;
};

// RadarChart Component
export const RadarChart: FC<Props> = (props: Props) => {

    // Props
    const { data = [], radarOptions = [], dataKey } = props;
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <RadarChrt cx="50%" cy="50%" outerRadius="80%" data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey={dataKey} />
                <PolarRadiusAxis />
                {
                    radarOptions.map((option: any) => {
                        return !option.key ? null :
                        <Radar key={`radar-${option.key}`} name={`radar-${option.key}`} dataKey={option.key} stroke={option.strokeColor || getRandomColor()} fill={option.fillColor || getRandomColor()} fillOpacity={option.opacity || 0.6} />
                    })
                }
                <Legend verticalAlign="bottom" height={36}/>
            </RadarChrt>
        </ResponsiveContainer>
    )
};