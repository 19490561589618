import { FC } from 'react';
import { ModalProps } from './Overlay/Modal.props';
import { Col } from '../Layout/Flex/Col/Col.com';
import { Overlay } from './Overlay/Overlay.com';
import { Grid } from '../Layout/Grid/Grid.com';
import { Row } from '../Layout/Flex/Row/Row.com';
import { Title } from '../Title/Title.com';
import { Icon } from '../Icon/Icon.com';
import { Button } from '../Button/Button.com';
import css from './Modal.module.scss';

type Props = ModalProps;

// Modal Component
export const Modal: FC<Props> = (props: Props) => {
    const { show = false, width, height, title = 'Header', onApply, onCancel, children } = props;
    const { cancelText = 'Cancel', applyText = 'Apply', hideFooter = false } = props;

    // Handle Apply
    const handleApply = () => {
        onApply && onApply();
    };

    // Handle Close
    const handleClose = () => {
        onCancel && onCancel();
    };

    return show === false ? null : (
        <Col className={css.Modal}>
            
            {/* Overlay */}
            <Overlay />

            {/* Window */}
            <Grid className={css.Window} type="row" template={hideFooter ? '40px 1fr' : '40px 1fr 40px'} style={{width, height}}>

                {/* Header */}
                <Row alignitems="center">
                    
                    {/* Title */}
                    <Row grow="1">
                        <Title>{title}</Title>
                    </Row>
                    
                    {/* Close */}
                    <Row>
                        <Icon name="close" onClick={handleClose} />
                    </Row>
                </Row>

                {/* Content */}
                <Col paddingTop="1" paddingBottom="1" scroll={true}>{children}</Col>

                {/* Footer */}
                {
                    hideFooter === true ? null : 
                    <Row justifycontent="end">

                        {/* Cancel */}
                        <Row>
                            <Button onClick={handleClose}>{cancelText}</Button>
                        </Row>
                        
                        {/* Apply */}
                        <Row paddingLeft="0.5">
                            <Button onClick={handleApply}>{applyText}</Button>
                        </Row>
                    </Row>
                }
            </Grid>
        </Col>
    )
};