import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { useAuth } from '../../../Hooks/UseAuth/UseAuth.hook';
import css from './Login.module.scss';

// Login Component
export const Login: FC = () => {
    const [formData, setFormData] = useState({username: '', password: ''});
    const { login } = useAuth();

    // On Input Change
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
    };

    // On Submit
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        login(formData.username, formData.password);
    };

    return (
        <Col className={css.Login} alignitems="center" justifycontent="center">
            <form className={css.Form} onSubmit={onSubmit}>

                {/* Logo */}
                <Row>
                    <svg width="132" height="36" viewBox="0 0 132 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M29.8673 31.3922C33.5313 28.0986 35.837 23.3134 35.837 17.9876C35.837 8.05332 27.8146 0 17.9185 0C8.02238 0 0 8.05332 0 17.9876C0 23.3099 2.30263 28.0922 5.9624 31.3856C9.63551 28.7624 13.0486 23.8521 14.9191 20.4697C15.0836 20.1724 15.5273 20.3158 15.4582 20.6485C14.6692 24.4463 12.5948 30.1566 8.80305 33.477C9.3236 33.7864 9.8611 34.07 10.4138 34.3263C14.0233 30.4923 15.6323 25.2238 16.4772 21.6076C16.557 21.2659 17.0471 21.3086 17.0556 21.6594C17.1867 27.0319 16.568 31.5117 14.2382 35.5954C14.7143 35.6952 15.1973 35.776 15.6865 35.837C16.3322 34.4561 16.9067 32.8069 17.4382 31.1631C17.5887 30.6977 18.2507 30.6977 18.4012 31.1631C18.9328 32.8068 19.5071 34.4559 20.1528 35.8367C20.6366 35.7763 21.1145 35.6965 21.5855 35.5982C19.2553 31.5142 18.6366 27.0342 18.7676 21.6613C18.7762 21.3106 19.2662 21.2679 19.346 21.6096C20.1911 25.2267 21.8008 30.4971 25.4123 34.3313C25.9655 34.0753 26.5034 33.7918 27.0244 33.4826C23.23 30.1628 21.1544 24.4497 20.365 20.6505C20.2959 20.3178 20.7397 20.1744 20.9041 20.4717C22.7758 23.856 26.1917 28.7701 29.8673 31.3922Z" fill="#262D53"></path>
                        <path d="M18.3746 13.8877C18.4677 12.0909 17.6008 10.9493 16.5763 10.3993C16.4395 10.3259 16.3137 10.5611 16.3404 10.7141C16.6657 12.5772 15.1813 14.3129 15.6726 16.1393C15.8861 16.9331 16.3529 17.5406 16.8098 17.9236C16.9455 18.0373 17.1314 17.9069 17.1129 17.7309C17.0478 17.1105 17.1835 16.6314 17.349 16.3791C17.3989 16.3031 17.4884 16.2683 17.5793 16.2683C17.7686 16.2683 17.9198 16.4224 17.9427 16.6103C18.0306 17.3314 18.3869 17.8998 18.7682 18.3153C18.835 18.388 18.9476 18.3949 19.0257 18.3346C21.5371 16.3963 20.7829 14.2039 19.843 13.1329C19.7428 13.0187 19.5607 13.0715 19.4987 13.2102C19.2625 13.7381 18.8458 14.0046 18.6301 14.087C18.6108 14.0944 18.5904 14.0975 18.5698 14.0975C18.4569 14.0975 18.3688 14.0004 18.3746 13.8877Z" fill="#FF5252"></path>
                        <path d="M60.2319 26.2806V22.5042H50.9894V9.55664H47.1836V26.2806H60.2319Z" fill="#262D53"></path>
                        <path d="M61.8623 26.2786L66.2117 26.2786L71.9204 16.8377L77.6291 26.2786L81.9785 26.2786L71.9204 9.55469L61.8623 26.2786Z" fill="#FF5252"></path>
                        <path d="M96.1104 9.55535L91.7609 9.55535L86.0523 18.9963L80.3436 9.55535L75.9941 9.55535L86.0523 26.2793L96.1104 9.55535Z" fill="#262D53"></path>
                        <path d="M90.1338 26.2786L94.4832 26.2786L100.192 16.8377L105.901 26.2786L110.25 26.2786L100.192 9.55469L90.1338 26.2786Z" fill="#262D53"></path>
                        <path d="M111.884 26.2786L116.233 26.2786L121.942 16.8377L127.651 26.2786L132 26.2786L121.942 9.55469L111.884 26.2786Z" fill="#262D53"></path>
                    </svg>
                </Row>

                {/* Title */}
                <Row>
                    <h1 className={css.Title}>Login</h1>
                </Row>

                {/* Username */}
                <Row>
                    <input 
                        className={css.Input} 
                        type="text" 
                        name="username" 
                        value={ formData.username } 
                        onChange={ handleInputChange }
                        placeholder="username" 
                        autoFocus={ true } 
                        required 
                    />
                </Row>

                {/* Password */}
                <Row>
                    <input 
                        className={css.Input} 
                        type="password" 
                        name="password" 
                        value={ formData.password } 
                        onChange={ handleInputChange }
                        placeholder="password" 
                        required 
                    />
                </Row>

                {/* Submit */}
                <Row>
                    <input className={css.Submit} type="submit" value="Login" />
                </Row>
            </form>
        </Col>
    )
};