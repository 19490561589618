import { FC } from 'react';
import { LineChart } from './LineChart/LineChart.com';
import { BarChart } from './BarChart/BarChart.com';
import { PieChart, PieDataType } from './PieChart/PieChart.com';
import { RadarChart } from './RadarChart/RadarChart.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Title } from '../../Simple/Title/Title.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import css from './Chart.module.scss';

type ChartConfig = {
    chartType: 'line' | 'bar' | 'pie' | 'radar';
    title?: string;
    data: any[] | any | PieDataType;
    lines?: any[];
    bars?: any[];
    radarOptions?: any[];
    dataKey?: string;
    xAxisKey?: string;
    tooltip?: boolean;
}
type Props = {
    config: ChartConfig;
};

// Chart Component
export const Chart: FC<Props> = (props: Props) => {

    // Props
    const { config } = props;
    const { chartType, title, data, lines = [], bars = [], tooltip = true, radarOptions, dataKey, xAxisKey} = config;
    
    return (
        <Col className={css.Chart} grow="1">

            {/* Title */}
            {
                !title ? null : 
                <Row paddingBottom="0.5" paddingLeft="1" paddingTop="0.5">
                    <Title className={css.Title} size="2">{title}</Title>
                </Row>
            }

            {/* Line Chart */}
            {
                chartType !== 'line' ? null : (
                    <LineChart data={data} lines={lines} tooltip={tooltip} />
                )
            }

            {/* Bar Chart */}
            {
                chartType !== 'bar' ? null : (
                    <BarChart data={data} bars={bars} tooltip={tooltip} xAxisKey={xAxisKey} />
                )
            }

            {/* Pie Chart */}
            {
                chartType !== 'pie' ? null : (
                    <PieChart data={data} tooltip={tooltip} />
                )
            }

            {/* Radar Chart */}
            {
                chartType !== 'radar' ? null : (
                    <RadarChart data={data} radarOptions={radarOptions} dataKey={dataKey} />
                )
            }
        </Col>
    )
};