import { SubscriptionsHookResult } from './Subscriptions.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useSubscriptions = (): SubscriptionsHookResult => {

    // Request Subscriptions Data
    const requestSubscriptionsData = (id?: string) => {
        const params: string = id ? `subscription_template=${id}` : ''; // subscription_id
        
        // Request Subscriptions Data
        return requestPost(`${API_URL}/subscriptions/get_subscriptions?${params}`);
    };

    // Add row
    const addRow = (data: any = {}) => {
        return requestPost(`${API_URL}/subscriptions/create`, {
            body: JSON.stringify(data)
        });
    };

    // Update row
    const updateRow = (data: any = {}) => {
        return requestPost(`${API_URL}/subscriptions/update`, {
            body: JSON.stringify(data)
        });
    };

    // Delete row
    const deleteRow = (id: string = '') => {
        return requestPost(`${API_URL}/subscriptions/delete`, {
            body: JSON.stringify({id})
        });
    };

    return {
        requestSubscriptionsData,
        addRow,
        updateRow,
        deleteRow
    };
};