import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccounts } from '../../../Hooks/Accounts/Accounts.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import css from './AccountDetails.module.scss';

// AccountDetails Component
export const AccountDetails: FC = () => {

    // Hooks
    const { accountName = '' } = useParams();
    const { requestAccountDetails } = useAccounts();

    // States
    const [html, setHtml] = useState<string>('');

    // Did Mount
    useEffect(() => {
        if (accountName !== '') {
            requestAccountDetails(accountName).then((html: string) => {
                if (html !== '') {
                    setHtml(html);
                }
            });
        } 
    }, [accountName]);

    return (
        <Col className={css.AccountDetails} grow="1">
            
            {/* Page Title */}
            <PageTitle title={`Account Details${accountName ? ' - ' + accountName : ''}`} />

            {/* HTML */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <div dangerouslySetInnerHTML={{__html: html}}></div>
            </Col>
        </Col>
    )
};