import { useParams } from 'react-router-dom';
import { EmailTemplatesHookResult } from './EmailTemplates.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useEmailTemplates = (): EmailTemplatesHookResult => {

    // Hooks
    const { details } = useParams();

    // Request EmailTemplates Data
    const requestEmailTemplatesData = () => {
        const params = new URLSearchParams(details);
        const queryName = params.get('queryName');
        const eventType = params.get('eventType');
        const daysBack = params.get('daysBack');
        const body: any = {};

        // Set Body Params
        queryName && (body['query_name'] = queryName);
        eventType && (body['event_type'] = eventType);
        daysBack && (body['days_back'] = daysBack);
        
        // Request EmailTemplates Data
        return requestPost(`${API_URL}/email_templates/get_templates`, {
            body: JSON.stringify(body)
        });
    };

    // Add row
    const addRow = (data: any = {}) => {
        return requestPost(`${API_URL}/email_templates/create`, {
            body: JSON.stringify(data)
        });
    };

    // Update row
    const updateRow = (data: any = {}) => {
        return requestPost(`${API_URL}/email_templates/update`, {
            body: JSON.stringify(data)
        });
    };

    // Delete row
    const deleteRow = (id: string = '') => {
        return requestPost(`${API_URL}/email_templates/delete`, {
            body: JSON.stringify({id})
        });
    };

    return {
        requestEmailTemplatesData,
        addRow,
        updateRow,
        deleteRow
    };
};