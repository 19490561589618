import { FC, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Col } from '../../Layout/Flex/Col/Col.com';
import { Row } from '../../Layout/Flex/Row/Row.com';
import { Text } from '../../Text/Text.com';
import css from './Options.module.scss';
import { Icon } from '../../Icon/Icon.com';

export type OptionType = (string | Record<string, string>) & {selected?: boolean};

type Props = {
    innerRef: React.RefObject<HTMLDivElement>;
    dropdownRef: React.RefObject<HTMLDivElement>;
    data: OptionType[];
    labelKey?: string;
    valueKey?: string;
    onSelect: (option: OptionType) => void;
    multiSelect?: boolean;
};

// Options Component
export const Options: FC<Props> = (props: Props) => {

    // Props
    const { innerRef, dropdownRef, data = [], labelKey = 'name', valueKey = 'value', onSelect, multiSelect = false } = props;

    // States
    const [portalId] = useState('dropdown-options-portal');
    const [portal, setPortal] = useState<HTMLDivElement | null>(null);

    // Vars
    const dropdownRect = dropdownRef.current?.getBoundingClientRect();
    const optionsStyle = {
        top: `${dropdownRect?.bottom}px`,
        left: `${dropdownRect?.left}px`,
        width: `${dropdownRect?.width}px`
    };

    useLayoutEffect(() => {
		const portalEl = document.createElement("div");
        portalEl.id = portalId;
        document.body.append(portalEl);

        setPortal(portalEl);

		return () => {
            portalEl.remove();
		};
	}, []);

    // Get Option Value
    function getOptionValue(option: OptionType): string {
        if (option.hasOwnProperty(valueKey) === false) {
            throw new Error(`${valueKey} is not a property of options, please check valueKey="${valueKey}"`);
        }
        return typeof option === 'string' ? option : option[valueKey];
    }

    // Get Option Label
    function getOptionLabel(option: OptionType): string {
        if (option.hasOwnProperty(labelKey) === false) {
            throw new Error(`${labelKey} is not a property of options, please check labelKey="${labelKey}"`);
        }
        return typeof option === 'string' ? option : option[labelKey];
    }

    return portal === null ? null : ReactDOM.createPortal(
        <Col innerRef={innerRef} className={css.Options} style={optionsStyle}>
            {
                data.map((option, index) => (
                    <Row key={index} className={css.Option} onClick={() => onSelect(option)} alignitems="center">
                        {
                            multiSelect === false ? null :
                            <Row paddingRight="0.5">
                                <Icon name={option.selected ? 'checked' : 'unchecked'} />
                            </Row>
                        }
                        <Text size="1">{getOptionLabel(option)}</Text>
                    </Row>
                ))
            }
        </Col>,
        portal as HTMLElement
    )
};