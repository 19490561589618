import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { QueryType } from '../../../Redux/Slices/Queries/Queries.slice.types';
import { useQueries } from '../../../Hooks/Queries/Queries.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';
import css from './Queries.module.scss';

// Queries Component
export const Queries: FC = () => {

    const { requestQueriesData, queries = [] } = useQueries();

    // Create URL
    const createURL = (queryName: string, eventType: string, daysBack: number) => {
        const url = '/query';
        const params = [];

        queryName && params.push(`queryName=${queryName}`);
        eventType && params.push(`eventType=${eventType}`);
        daysBack && params.push(`daysBack=${daysBack}`);

        return `${url}/${params.join('&')}`;
    };

    // Did Mount
    useEffect(() => {
        requestQueriesData();
    }, []);

    return (
        <Col className={css.Queries}>

            {/* Page Title */}
            <PageTitle title="Queries" />

            {/* List of Queries */}
            <Col className={css.List} paddingLeft="1" paddingRight="1">
                {
                    queries.map(({text, queryName, eventType, daysBack}: QueryType, index: number) => {
                        return (
                            <Row className={css.Item} key={`query_${index}`} justifycontent="center" paddingTop="0.5" paddingBottom="0.5" paddingLeft="0.5" paddingRight="0.5">
                                <Text className={css.Element}>
                                    <Link className={css.Element} to={createURL(queryName, eventType, daysBack)}>{text}</Link>
                                </Text>
                            </Row>
                        )
                    } )
                }
            </Col>

        </Col>
    )
};