import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccounts } from '../../../Hooks/Accounts/Accounts.hook';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Text } from '../../Simple/Text/Text.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import css from './Accounts.module.scss';

// Accounts Component
export const Accounts: FC = () => {

    const { keys = [], data = {}, requestAccountsData, selectAccount } = useAccounts();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    // Select Account
    const onSelectAccount = (account: string) => {
        selectAccount(account);
    };

    // Toggle Key
    const toggleKey = (key: string) => {

        // Remove
        if (selectedKeys.includes(key)) {
            setSelectedKeys(selectedKeys.filter((k) => k !== key));
        }

        // Add
        else {
            setSelectedKeys([...selectedKeys, key]);
        }
    };

    // Filter Cards
    const filterCards = (key: string) => {
        return selectedKeys.length === 0 ? true : selectedKeys.includes(key);
    };

    // Did Mount
    useEffect(() => {
        requestAccountsData();
    }, []);

    return (
        <Col className={css.Accounts} paddingBottom="1" paddingLeft="1" paddingRight="1">

            {/* Page Title */}
            <PageTitle title="Accounts" />

            {/* Chips */}
            <Row className={css.Chips} alignitems="center" justifycontent="center" paddingBottom="2">
                {
                    keys.map((key: string, index: number) => {
                        return (
                            <Row 
                                key={`parent_${index}`}    
                                className={css.Item} 
                                paddingLeft="0.75" 
                                paddingRight="0.75"
                                paddingTop="0.125"
                                paddingBottom="0.125"
                                data-active={selectedKeys.includes(key) ? 'true' : undefined}
                                onClick={() => toggleKey(key)}
                            >{key}</Row>
                        )
                    })
                
                }
            </Row>

            {/* Accounts Cards */}
            <Row className={css.AccountsCards} justifycontent="center" wrap="wrap">
                {
                    keys.filter(filterCards).map((key: string, index: number) => {
                        return (
                            <Col 
                                key={`card_${index}`}    
                                className={css.Card} 
                                paddingLeft="1" 
                                paddingRight="1"
                                paddingTop="1"
                                paddingBottom="1"
                            >
                                {/* Card Title */}
                                <Row className={css.CardTitle} paddingBottom="1">
                                    <Row grow="1">{key}</Row>
                                    <Row>
                                        <Link to={`/account/details/${key}`}>
                                            <Icon className={css.Link} name="open" size={18} />
                                        </Link>
                                    </Row>
                                </Row>

                                {/* Accounts List */}
                                <Col className={css.AccountsList}>
                                    {
                                        data[key].map((account: string, index: number) => {
                                            return (
                                                <Row key={`account_${key}_${index}`} className={css.Item} paddingBottom="0.125">
                                                    <Row onClick={() => onSelectAccount(account)}>
                                                        <Text className={css.Link}>{account}</Text>
                                                    </Row>
                                                </Row>
                                            )
                                        
                                        })
                                    }
                                </Col>
                            </Col>
                        )
                    })
                }
            </Row>
        </Col>
    )
};