import { FC } from 'react';
import { TitleProps } from './Title.props';
import { Text } from '../Text/Text.com';

type Props = TitleProps;

// Title Component
export const Title: FC<Props> = (props: Props) => {
    const { size = '4', bold = true, ...rest } = props;

    return <Text {...rest} size={size} bold={bold} />
};