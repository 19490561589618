import { FC, memo, forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { ColDef, ICellEditorParams } from 'ag-grid-community';
import { Input } from '../../../../Simple/Form/Input/Input.com';
import css from './NumberCellEditor.module.scss';

type Props = ICellEditorParams & {
    colDef: ColDef & { 
        min?: number, 
        max?: number, 
        step?: number 
    }
};

// NumberCellEditor Component
export const NumberCellEditor: FC<Props> = memo(forwardRef((props: Props, ref) => {
    const [value, setValue] = useState(props.value);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const { min, max, step } = props.colDef;

    // Handle Change
    const handleChange = (name: string, value: string) => {
        setValue(value);
    };

    // Component Editor Lifecycle methods
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        }
    });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    
    return (
        <Input 
            innerRef={inputRef} 
            className={css.NumberCellEditor}
            type="number" 
            name="cell_editor_number" 
            value={value} 
            min={min}
            max={max}
            step={step}
            onChange={handleChange} 
        />
    )
}));