import { useParams } from 'react-router-dom';
import { EmailTemplatesEditorHookResult } from './EmailTemplatesEditor.hook.types';
import { UpdateSchemaProps } from '../../Types/UpdateSchema.type';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useEmailTemplatesEditor = (): EmailTemplatesEditorHookResult => {

    // Hooks
    const { templateId, subscriptionId } = useParams();

    // Request EmailTemplate Data
    const requestEmailTemplateData = () => {
        const body: any = {
            template_id: templateId,
            subscription_id: subscriptionId
        };
        
        // Request EmailTemplates Data
        return requestPost(`${API_URL}/email_templates/editor`, {
            body: JSON.stringify(body)
        });
    };

    // Update Template
    const updateTemplate = (data: UpdateSchemaProps) => {
        console.log(data);
        return requestPost(`${API_URL}/email_templates/update`, {
            body: JSON.stringify(data)
        });
    };

    return {
        requestEmailTemplateData,
        updateTemplate
    };
};