import { FC } from 'react';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import css from './ChartPage.module.scss';

// ChartPage Component
export const ChartPage: FC = () => {

    return (
        <Col className={css.ChartPage} grow="1">
            
            {/* Page Title */}
            <PageTitle title="Chart Page" />
        </Col>
    )
};