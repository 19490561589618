import { createSlice } from '@reduxjs/toolkit';
import { AccountsSliceType } from './Accounts.slice.types';

const initialState: AccountsSliceType = {
    keys: [],
    data: {},
    selected: ''
};

export const AccountsSlice = createSlice({
    name: 'Accounts',
    initialState,
    reducers: {

        // Set Accounts Data Action
        setAccountsDataAction: (state, action) => {
            const data = action.payload;
            const keys = Object.keys(data);

            state.keys = keys;
            state.data = data;
        },

        // Set Selected Account Action
        setSelectedAccountAction: (state, action) => {
            if (action.payload) {
                state.selected = action.payload;
            }
        },

        // Reset Account Action
        resetAccountAction: (state) => {
            return {...initialState};
        }
    }
});

export const {
    setAccountsDataAction,
    setSelectedAccountAction,
    resetAccountAction
} = AccountsSlice.actions;
export default AccountsSlice.reducer;