import { FC, memo, forwardRef, useState, useImperativeHandle, useEffect, useRef } from 'react';
import { SuppressKeyboardEventParams } from 'ag-grid-community';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { Modal } from '../../../../Simple/Modal/Modal.com';
import { HtmlEditor } from '../../../HtmlEditor/HtmlEditor.com';

type Props = ICellEditorParams;

// HtmlCellEditor Component
export const HtmlCellEditor: FC<Props> = memo(forwardRef((props: Props, ref) => {

    // Props
    const { value: initialValue, stopEditing, colDef } = props;    

    // States
    const [value, setValue] = useState(initialValue);

    // Refs
    const originalSuppressKeyboardEvent = useRef(colDef.suppressKeyboardEvent);
    const applyPressedRef = useRef(false);

    // Handle Change
    const handleChange = (name: string, value: string) => {
        setValue(value);
    };

    // Handle Apply
    const handleApply = () => {
        applyPressedRef.current = true;
        stopEditing(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        applyPressedRef.current = false;
        stopEditing(true);
    };

    // Suppress Keyboard Event
    const suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
        const { event } = params;
        const { key } = event;

        if (key === 'Enter') {
            return true;
        }
        
        return false;
    };

    // Component Editor Lifecycle methods
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return applyPressedRef.current === true ? false : true;
            }
        }
    }, [value]);

    useEffect(() => {
        colDef.suppressKeyboardEvent = suppressKeyboardEvent;
        return () => {
            colDef.suppressKeyboardEvent = originalSuppressKeyboardEvent.current;
        }
    }, []);

    return (
        <Modal 
            show={true} 
            title="Html Cell Editor"
            width="75%"
            height="75%"
            onApply={handleApply}
            onCancel={handleCancel}
        >
            <HtmlEditor name="cell" value={value} onChange={handleChange} />
        </Modal>
    )
}));