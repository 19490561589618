import { useParams } from 'react-router-dom';
import { BillingDetailsHookResult } from './BillingDetails.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useBillingDetails = (): BillingDetailsHookResult => {

    // Hooks
    const { details } = useParams();

    // Request BillingDetails Data
    const requestBillingDetailsData = () => {
        const params = new URLSearchParams(details);
        const patientId = params.get('patient_id');
        const daysBack = params.get('days_back');
        const body: any = {};

        // Set Body Params
        patientId && (body['patient_id'] = patientId);
        daysBack && (body['days_back'] = daysBack);
        
        // Request BillingDetails Data
        return requestPost(`${API_URL}/billing__details/get_details`, {
            body: JSON.stringify(body)
        });
    };

    // Request Chart Data
    const requestChartData = () => {
        const params = new URLSearchParams(details);
        const patientId = params.get('patient_id');
        const daysBack = params.get('days_back');
        const body: any = {};

        // Set Body Params
        patientId && (body['patient_id'] = patientId);
        daysBack && (body['days_back'] = daysBack);

        return requestPost(`${API_URL}/billing__details/get_chart_data`, {
            body: JSON.stringify(body)
        });
    };

    return {
        requestBillingDetailsData,
        requestChartData
    };
};