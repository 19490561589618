import { AWVHookResult } from './AWV.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useAWV = (): AWVHookResult => {

    // Request AWV Data
    const requestAWVData = () => {
        return requestPost(`${API_URL}/queries/get_query`, {
            body: JSON.stringify({
                query_name: 'not_visited_this_year'
            })
        });
    };

    return {
        requestAWVData
    };
};