import { FC } from 'react';
import { TextProps } from './Text.types';
import classNames from '../../../Utils/ClassNames';
import css from './Text.module.scss';

type Props = TextProps;

// Text Component
export const Text: FC<Props> = (props: Props) => {
    const { size = '2', color = 'primary', bold = false, style, className, children } = props;

    return (
        <span 
            data-size={size} 
            data-color={color}
            data-bold={bold}
            className={classNames(css.Text, className)} 
            style={{...style, color: color}} 
        >{children}</span>
    )
};