import { FC, useState } from 'react';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';

import { Dropdown, OptionType } from '../../Simple/Form/Dropdown/Dropdown.com';
import { API_URL } from '../../../Constants/Constants';
import css from './Sandbox.module.scss';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';

const initialOptions = [
    {id: '1', name: 'Option 1', value: 'option1 aaa'},
    {id: '2', name: 'Option 2', value: 'option2 bbb'},
    {id: '3', name: 'Option 3', value: 'option3 ccc'},
];

// Sandbox Component
export const Sandbox: FC = () => {

    // States
    const [options, setOptions] = useState(initialOptions);
    
    const onSelect = (name: string | string[], value: string) => {
        console.log('SELECTED', name, value);
    };

    const onSelectMultiple = (name: string | string[], value: string) => {
        setOptions((state: any) => {
            const updatedState = state.map((option: any) => {
                if (option.value === value) {
                    return {
                        ...option,
                        selected: !option.selected
                    };
                }
                return option;
            });
            return updatedState;
        });
    };

    console.log('RENDER', options);
    

    return (
        <Col className={css.Sandbox} grow="1">

            {/* Page Title */}
            <PageTitle title="Sandbox" />

            {/* Content */}
            <Row paddingLeft="1" paddingRight="1" paddingBottom="1">
                
                {/* Autocomplete Dropdown */}
                <Row>
                    <Dropdown 
                        name="email_template"
                        // options={options} 
                        labelKey="name"
                        valueKey="_id"
                        searchKey="template_name"
                        onChange={onSelect} 
                        autocomplete={true} 
                        apiUrl={`${API_URL}/email_templates/get_templates_autocomplete`}
                        placeholder="Select Template"
                        required={true}
                    />
                </Row>

                {/* Static Options Dropdown */}
                <Row>
                    <Dropdown 
                        name="custom_option"
                        options={options} 
                        labelKey="name"
                        valueKey="value"
                        searchKey="template_name"
                        onChange={onSelect} 
                        placeholder="Select Template"
                        required={true}
                    />
                </Row>

                {/* Multi Select Options Dropdown */}
                <Row>
                    <Dropdown 
                        name="custom_option"
                        options={options} 
                        labelKey="name"
                        valueKey="value"
                        searchKey="template_name"
                        onChange={onSelectMultiple} 
                        placeholder="Select Template"
                        required={true}
                        multiSelect={true}
                    />
                </Row>

                {/* Icon Checked */}
                <Row>
                    <Icon name="checked" />
                    <Icon name="unchecked" />
                </Row>
            </Row>
            
        </Col>
    )
};