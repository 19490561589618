import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

// Auth
import { AuthProvider } from './AuthProvider.com';
import { Login } from '../Complex/Login/Login.com';
import { Dashboard } from '../Complex/Dashboard/Dashboard.com';
import { HomePage } from '../Complex/HomePage/HomePage.com';
import { Accounts } from '../Complex/Accounts/Accounts.com';
import { AccountDetails } from '../Complex/AccountDetails/AccountDetails.com';

// Reports and Data Analysis
import { Queries } from '../Complex/Queries/Queries.com';
import { Query } from '../Complex/Query/Query.com';
import { IDEReports } from '../Complex/IDEReports/IDEReports.com';
import { DailyHuddle } from '../Complex/DailyHuddle/DailyHuddle.com';
import { Map } from '../Complex/Map/Map.com';

// Pathways
import { Subscriptions } from '../Complex/Subscriptions/Subscriptions.com';
import { EmailTemplateEditor } from '../Complex/EmailTemplateEditor/EmailTemplateEditor.com';
import { EmailTemplates } from '../Complex/EmailTemplates/EmailTemplates.com';
import { PageTemplates } from '../Complex/PageTemplates/PageTemplates.com';
import { QrdaTemplates } from '../Complex/QrdaTemplates/QrdaTemplates.com';
import { SubscriptionTemplates } from '../Complex/SubscriptionTemplates copy/SubscriptionTemplates.com';
import { ChartPage } from '../Complex/ChartPage/ChartPage.com';

// Operational
import { AWV } from '../Complex/AWV/AWV.com';
import { CodesOptimization } from '../Complex/CodesOptimization/CodesOptimization.com';
import { PatientsOptimization } from '../Complex/PatientsOptimization/PatientsOptimization.com';
import { BillingDetails } from '../Complex/BillingDetails/BillingDetails.com';
import { BillingProblem } from '../Complex/BillingProblem/BillingProblem.com';

// Not Found
import { Sandbox } from '../Complex/Sandbox/Sandbox.com';
import { NotFound } from '../Complex/NotFound/NotFound.com';

// RoutesProvider Component
export const RoutesProvider: FC = () => {

    return (
        <Routes>
            <Route path="/" element={<AuthProvider/>}>
                
                {/* Auth */}
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<HomePage />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/account/details/:accountName" element={<AccountDetails />} />
                
                {/* Reports and Data Analysis */}
                <Route path="/queries" element={<Queries />} />
                <Route path="/query/:details" element={<Query />} />
                <Route path="/ide_reports" element={<IDEReports />} />
                <Route path="/daily_huddle" element={<DailyHuddle />} />
                <Route path="/map" element={<Map />} />
                <Route path="/dashboard" element={<Dashboard />} />
                
                {/* Pathways */}
                <Route path="/subscriptions/:id?" element={<Subscriptions />} />
                <Route path="/email_template_editor/:templateId/:subscriptionId" element={<EmailTemplateEditor />} />
                <Route path="/email_templates" element={<EmailTemplates />} />
                <Route path="/page_templates" element={<PageTemplates />} />
                <Route path="/qrda_templates" element={<QrdaTemplates />} />
                <Route path="/subscription_templates" element={<SubscriptionTemplates />} />
                <Route path="/chart_page" element={<ChartPage />} />

                {/* Operational */}
                <Route path="/awv" element={<AWV />} />
                <Route path="/codes_optimization" element={<CodesOptimization />} />
                <Route path="/patients_optimization" element={<PatientsOptimization />} />
                <Route path="/billing_details/:details?" element={<BillingDetails />} />
                <Route path="/get_billing_problem/:details?" element={<BillingProblem />} />
                
                {/* Sandbox/Not Found */}
                <Route path="/sandbox" element={<Sandbox />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )
};