import { useDispatch, useSelector } from 'react-redux';
import { QueryType } from '../../Redux/Slices/Queries/Queries.slice.types';
import { QueriesHookState, QueriesHookResult } from './Queries.hook.types';
import { setQueriesDataAction, resetQueriesAction } from '../../Redux/Slices/Queries/Queries.slice';
import { API_URL } from '../../Constants/Constants';

export const useQueries = (): QueriesHookResult => {
    const queries = useSelector((state: {queries: QueriesHookState}) => state.queries.queries);
    const dispatch = useDispatch();

    // Request Queries Data
    const requestQueriesData = () => {
        fetch(`${API_URL}/queries/get_queries`, {
            credentials: 'include'
        })
        .then((response) => response.json())
        .then((result) => {
            if (result && result.data) {
                const data: QueryType[] = result.data.map((row: any) => {
                    return {
                        queryName: row.query_name,
                        eventType: row.event_type,
                        daysBack: row.days_back,
                        text: row.text
                    };
                });
                dispatch(setQueriesDataAction({data}));
            }
        });
    };

    // Reset
    const reset = () => {
        dispatch(resetQueriesAction());
    };

    return {
        queries,
        requestQueriesData,
        reset
    };
};