import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { useCharts } from '../../../Hooks/Charts/Charts.hook';
import { Chart } from '../Charts/Chart.com';
import css from './Dashboard.module.scss';

// Dashboard Component
export const Dashboard: FC = () => {

  // Hooks
  const { requestChartsData } = useCharts();

  // States
  const [title, setTitle] = useState('Dashboard');
  const [chartsData, setChartsData] = useState<string[]>([]);

  // Did Mount
  useEffect(() => {
    requestChartsData().then((data: any = {}) => {
        const { title, chartsData = [] } = data;
        
        title && setTitle(title);
        setChartsData(chartsData);
    });
}, []);

  return (
      <Col className={css.Dashboard} grow="1">
          
          {/* Page Title */}
          <PageTitle title={title} />

          {/* Charts */}
          <div className={css.ChartsContainer}>
            {
              chartsData.map((config: any, index: number) => {
                return <Link to={config.url} key={`chart_${index}`} className={css.Card}>
                  <Chart config={config} />
                </Link>
              })
            }
          </div>
      </Col>
  )
};