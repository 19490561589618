import { FC, useEffect, useState } from 'react';
import { useAccounts } from '../../../Hooks/Accounts/Accounts.hook';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { LineChart } from '../Charts/LineChart/LineChart.com';

// AccountDashboardHeader Component
export const AccountDashboardHeader: FC = () => {

    // Hooks
    const { selected: selectedAccount = '', requestDashboardAccountHeader, requestAccountDashboardChartData } = useAccounts();

    // States
    const [html, setHtml] = useState<string>('');
    const [chartData, setChartData] = useState<any>({
        data: [],
        lines: [],
        tooltip: true
    });

    // Did Mount
    useEffect(() => {
        if (selectedAccount) {

            // Request Dashboard Account Header
            requestDashboardAccountHeader(selectedAccount).then((html: string) => {
                if (html !== '') {
                    setHtml(html);
                }
            });

            // Request Account Dashboard Chart Data
            requestAccountDashboardChartData(selectedAccount).then(({data = {}}: any) => {
                const { chartData, lines, tooltip, xAxisKey } = data;
                setChartData({data: chartData, lines, tooltip, xAxisKey});
            });
        }
        
    }, [selectedAccount]);
    
    return (
        <Row justifycontent="center" paddingTop="1">
            
            {/* Details */}
            <Row alignitems="center" justifycontent="center">
                <div style={{padding: "1rem"}} dangerouslySetInnerHTML={{__html: html}}></div>
            </Row>

            {/* Line Chart */}
            <Row grow="0" style={{width: '75%', minHeight: 300}}>
                <LineChart data={chartData.data} lines={chartData.lines} tooltip={chartData.tooltip} xAxisKey={chartData.xAxisKey} />
            </Row>
        </Row> 
    )
};