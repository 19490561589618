import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { HomePageCard } from '../HomePage.types';
import { Col } from '../../../Simple/Layout/Flex/Col/Col.com';
import { Row } from '../../../Simple/Layout/Flex/Row/Row.com';
import { Devider } from '../../../Simple/Devider/Devider.com';
import { Icon } from '../../../Simple/Icon/Icon.com';
import css from './Area.module.scss';

type Props = {
    title: string;
    cards: any[];
};

// Area Component
export const Area: FC<Props> = (props: Props) => {
    const { title, cards } = props;

    return (
        <Col className={css.Area}>
            
            {/* Devider */}
            <Devider text={title} />

            {/* Cards */}
            <Row className={css.Cards}>
                {
                    cards.map(({name, description, icon, link}: HomePageCard, index: number) => (
                        <Fragment key={index}>
                            
                            {/* Card  */}
                            <Link className={css.Card} to={link}>

                                {/* Title */}
                                <Row className={css.Title}>

                                    {/* Icon */}
                                    <Row className={css.Icon}>
                                        <Icon name={icon} />
                                    </Row>

                                    {/* Name */}
                                    <Row className={css.Name}>{name}</Row>
                                </Row>

                                {/* Description */}
                                <Row className={css.Description}>{description}</Row>
                            </Link>
                        </Fragment>
                    ))
                }
            </Row>
        </Col>
    )
};