import { FC } from 'react';
import { Button } from '../../Simple/Button/Button.com';
import { API_URL } from '../../../Constants/Constants';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    data?: any;
    value?: string;
};

// ResendRenderer Component
export const ResendRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const { _id } = data;

    // Handle Click
    const handleClick = () => {
        fetch(`${API_URL}/subscriptions/send?subscription_id=${_id}`)
            .then(response => response.json())
            .then(data => {alert(data.message);
            })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <Button onClick={handleClick}>
            <Text size="0" color='inherit'>Resend</Text>
        </Button>
    )
};