import { FC, useState, useEffect } from 'react';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { useBillingDetails } from '../../../Hooks/BillingDetails/BillingDetails.hook';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Devider } from '../../Simple/Devider/Devider.com';
import { DataTable } from '../DataTable/DataTable.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Link, useParams } from 'react-router-dom';
import css from './BillingDetails.module.scss';
import { LineChart } from '../Charts/LineChart/LineChart.com';

// BillingDetails Component
export const BillingDetails: FC = () => {
    
    // Hooks
    const { requestBillingDetailsData, requestChartData } = useBillingDetails();
    const { details } = useParams();
    
    // States
    const [title, setTitle] = useState('Billing Details');
    const [rows1, setRows1] = useState<any[]>([]);
    const [headers1, setHeaders1] = useState<string[]>([]);
    const [rows2, setRows2] = useState<any[]>([]);
    const [headers2, setHeaders2] = useState<string[]>([]);
    const [rows3, setRows3] = useState<any[]>([]);
    const [headers3, setHeaders3] = useState<string[]>([]);
    const [chartData, setChartData] = useState<any>({
        data: [],
        lines: [],
        tooltip: true
    });

    const columnModel1 = [
        { field: 'problem', renderer: 'html'}
    ];

    // Get Patient Id
    const getPatientId = (details?: string) => {
        if (details) {
            const params = new URLSearchParams(details);
            return '/' + params.get('patient_id');
        }
        return '';
    };

    // Did Mount
    useEffect(() => {

        // Request BillingDetails Data
        requestBillingDetailsData().then((data: any) => {
            const { title, headers: headers1, rows: rows1, headers1: headers2, rows1: rows2, headers2: headers3, rows2: rows3 } = data;
            
            const rowDataObjects1 = normalizeRows(headers1, rows1);
            const rowDataObjects2 = normalizeRows(headers2, rows2);
            const rowDataObjects3 = normalizeRows(headers3, rows3);
            
            // Fix url
            rowDataObjects1.forEach((row: any) => {
                row['problem'] = row['problem'].replace('get_billing_problem?', 'get_billing_problem/');
            });
            
            setTitle(title);
            
            setHeaders1(headers1);
            setRows1(rowDataObjects1);
            
            setHeaders2(headers2);
            setRows2(rowDataObjects2);
            
            setHeaders3(headers3);
            setRows3(rowDataObjects3);
        });

        // Request Chart Data
        requestChartData().then((data: any) => {
            const { chartData, lines, tooltip, xAxisKey } = data;
            setChartData({data: chartData, lines, tooltip, xAxisKey});
        });
    }, []);
    
    return (
        <Col className={css.BillingDetails} grow="1">
            
            {/* Page Title */}
            <Row alignitems="center" paddingRight="1">
                <Row grow="1">
                    <PageTitle title={title} />
                </Row>
                <Row>
                    <Link to={`https://hms.lavaa.health/patients${getPatientId(details)}`} target="_blank">Patients</Link>
                </Row>
            </Row>

            {/* Line Chart */}
            <Row style={{minWidth: 400, minHeight: 300}} paddingTop="1" paddingBottom="1">
                <LineChart data={chartData.data} lines={chartData.lines} tooltip={chartData.tooltip} xAxisKey={chartData.xAxisKey} />
            </Row>

            {/* Tables */}
            <Col grow="1" scroll={true}>
                <Col grow="1" style={{minHeight: '500px'}}>
                    
                    {/* Devider */}
                    <Devider text="MISSING CODES" />
                
                    {/* Table 1 */}
                    <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                        <DataTable 
                            headers={headers1} 
                            rows={rows1} 
                            columnModel={columnModel1}
                        />
                    </Col>
                </Col>

                <Col grow="1" style={{minHeight: '500px'}}>
                    
                    {/* Devider */}
                    <Devider text="BILLED CODES" />

                    {/* Table 2 */}
                    <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                        <DataTable 
                            headers={headers2} 
                            rows={rows2} 
                            // columnModel={columnModel}
                        />
                    </Col>
                </Col>

                <Col grow="1" style={{minHeight: '500px'}}>
                    
                    {/* Devider */}
                    <Devider text="SUGGESTIONS CODES" />

                    {/* Table 3 */}
                    <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                        <DataTable 
                            headers={headers3} 
                            rows={rows3} 
                            // columnModel={columnModel}
                        />
                    </Col>
                </Col>
            </Col>
        </Col>
    )
};