import { FC, memo, forwardRef, useState, useRef, useEffect, useImperativeHandle } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Input } from '../../../../Simple/Form/Input/Input.com';
import css from './TextCellEditor.module.scss';

type Props = ICellEditorParams;

// TextCellEditor Component
export const TextCellEditor: FC<Props> = memo(forwardRef((props: Props, ref) => {
    const [value, setValue] = useState(props.value);
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Handle Change
    const handleChange = (name: string, value: string) => {
        setValue(value);
    };

    // Component Editor Lifecycle methods
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            isCancelBeforeStart() {
                return false;
            },
            isCancelAfterEnd() {
                return false;
            }
        }
    });

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);
    
    return (
        <Input 
            innerRef={inputRef} 
            className={css.TextCellEditor}
            type="text" 
            name="cell_editor_text" 
            value={value} 
            onChange={handleChange} 
        />
    )
}));