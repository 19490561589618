import { FC } from 'react';
import { FlexElementProps } from './FlexElement.types';
import { ElementProps } from '../../Element/Element.types';
import classNames from '../../../../../Utils/ClassNames';
import { Element } from '../../Element/Element.com';
import css from './FlexElement.module.scss';

type Props = FlexElementProps & ElementProps;

// FlexElement Component
export const FlexElement: FC<Props> = (props: Props) => {
    const { 
        style, className, children,
        grow, shrink, alignitems, aligncontent, justifycontent, justifyitems, wrap,
        ...rest
    } = props;

    return (
        <Element
            { ...rest }
            data-grow={ grow }
            data-shrink={ shrink }
            data-alignitems={ alignitems }
            data-aligncontent={ aligncontent }
            data-justifycontent={ justifycontent }
            data-justifyitems={ justifyitems }
            data-wrap={ wrap }
            className={ classNames(css.FlexElement, className) } 
            style={ {...style} }
        >{children}</Element>
    )
};