import { FC, useEffect, useState } from 'react';
import { useBillingProblem } from '../../../Hooks/BillingProblem/BillingProblem.hook';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';
import css from './BillingProblem.module.scss';
import { normalizeRows } from '../../../Utils/NornalizeRowsData';
import { DataTable } from '../DataTable/DataTable.com';

type Props = {};

// BillingProblem Component
export const BillingProblem: FC<Props> = (props: Props) => {
    
    // Hooks
    const { requestBillingProblemData } = useBillingProblem();

    // States
    const [title, setTitle] = useState('Billing Problem');
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    // Did Mount
    useEffect(() => {
        requestBillingProblemData().then((data: any) => {
            const { title, headers, rows } = data;
            
            const rowDataObjects = normalizeRows(headers, rows);
            
            setTitle(title);
            setHeaders(headers);
            setRows(rowDataObjects);
        });
    }, []);

    return (
        <Col className={css.BillingProblem} grow="1">
            
            {/* Page Title */}
            <PageTitle title={title} />

            {/* Table 1 */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable 
                    headers={headers} 
                    rows={rows} 
                    // columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};