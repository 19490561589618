import { FC, useEffect, useState } from 'react';
import { UpdateSchemaProps } from '../../../Types/UpdateSchema.type';
import { useEmailTemplatesEditor } from '../../../Hooks/EmailTemplatesEditor/EmailTemplatesEditor.hook';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { FormData } from '../../Simple/Form/FormData.com';
import css from './EmailTemplateEditor.module.scss';

// EmailTemplateEditor Component
export const EmailTemplateEditor: FC = () => {

    // Hooks
    const { requestEmailTemplateData, updateTemplate } = useEmailTemplatesEditor();

    // States
    const [id, setId] = useState<string>('');
    const [formData, setFormData] = useState<any[]>([
        { name: 'name', type: 'text', value: '', required: true, placeholder: 'Name', label: 'Name' },
        { name: 'subject', type: 'text', value: '', required: true, placeholder: 'Subject', label: 'Subject' },
        { name: 'version', type: 'number', value: '', required: true, placeholder: 'Version', label: 'Version', min: 0.1, max: 100, step: 0.1 },
        { name: 'message', type: 'html', value: '', placeholder: 'Message', label: 'Message', style: {gridColumnStart: 1, gridColumnEnd: 4, alignItems: 'stretch', height: '100%'} },
        { type: 'submit', text: 'Save', style: {gridColumnStart: 1, gridColumnEnd: 4, justifyContent: 'end'} },
    ]);

    // Handle Form Data Change
    const handleFormDataChange = (name: string, value: string) => {
        const updateFormData = formData.map((item: any) => (item.name === name ? {...item, value} : item));
        setFormData(updateFormData);
    };

    // Handle Form Data Submit
    const handleFormDataSubmit = () => {
        const data: UpdateSchemaProps = formData.reduce((acc: UpdateSchemaProps, item: any) => {
            return {
                ...acc,
                data: {
                    ...acc.data,
                    ...(item.name ? {[item.name]: item.value} : {})
                },
                schema: {
                    ...acc.schema,
                    ...(item.name ? {fields: [...acc.schema.fields, item.name]} : {})
                }
            };
        }, { data: {_id: id}, schema: {primaryKey: '_id', fields: []}});

        updateTemplate(data);
    };

    // Did Mount
    useEffect(() => {
        requestEmailTemplateData().then((data: any) => {
            const { template } = data;
            const updateFormData = formData.map((item: any) => {

                if (template[item.name]) {
                    item.value = template[item.name];
                }

                return item;
            });
            
            setId(template._id);
            setFormData(updateFormData);
        });
    }, []);

    return (
        <Col className={css.EmailTemplateEditor} grow="1">

            {/* Page Title */}
            <PageTitle title="Editor" />

            {/* Editor */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                {/* <HtmlEditor onChange={handleChange} value={value} /> */}
                <FormData className={css.Form} config={formData} onChange={handleFormDataChange} onSubmit={handleFormDataSubmit} />
            </Col>

            {/* Save */}
            {/* <Row paddingLeft="1" paddingRight="1" paddingBottom="1" justifycontent="end">
                <Button onClick={handleSave}>Save</Button>
            </Row> */}
        </Col>
    )
};