import { useParams } from 'react-router-dom';
import { BillingProblemHookResult } from './BillingProblem.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useBillingProblem = (): BillingProblemHookResult => {

    // Hooks
    const { details } = useParams();

    // Request BillingProblem Data
    const requestBillingProblemData = () => {
        const params = new URLSearchParams(details);
        const patientId = params.get('patient_id');
        const icdCode = params.get('icd_code');
        const body: any = {};

        // Set Body Params
        patientId && (body['patient_id'] = patientId);
        icdCode && (body['icd_code'] = icdCode);
        
        // Request BillingProblem Data
        return requestPost(`${API_URL}/billing__details/get_problem`, {
            body: JSON.stringify(body)
        });
    };

    return {
        requestBillingProblemData
    };
};