import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { Toolbar } from './Toolbar.com';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import MonacoEditor, { monaco } from 'react-monaco-editor';
import { Editor as MonacoEditor, useMonaco } from '@monaco-editor/react';
import beautify from 'js-beautify';

type Props = {
    onChange?: (name: string, value: string) => void;
    name: string;
    value?: string;
};

// HtmlEditor Component
export const HtmlEditor: FC<Props> = (props: Props) => {

    // Ref
    const monacoRef = useRef<any>(null);

    // Hooks
    const monaco = useMonaco();

    // Props
    const { name, value: initialValue = '', onChange } = props;

    // States
    const [mode, setMode] = useState<'wysiwyg' | 'html'>('wysiwyg');
    const [value, setValue] = useState(initialValue);
    const [isModified, setIsModified] = useState(false);

    // Switch between WYSIWYG and Html modes
    const switchMode = () => {
        setMode(mode === 'wysiwyg' ? 'html' : 'wysiwyg');
    };

    // Handle Monaco Editor Change
    const handleMonacoEditorChange = (value: string | undefined) => {
        !isModified && setIsModified(true);
        value && setValue(beautify.html(value));
        onChange && onChange(name, value || '');
    };

    // Handle Quill Editor Change
    const handleQuillEditorChange = (value: string) => {
        !isModified && setIsModified(true);
        value && setValue(value);
        onChange && onChange(name, value || '');
    };

    // Handle Monaco Editor Mount
    const handleMonacoMount = useCallback((editor: any, monaco: any) => {
        monacoRef.current = editor;
    }, []);

    // Modules for Quill Editor
    const modules = useMemo(() => ({
        toolbar: {
            container: '#editor-toolbar'
        }
    }), [mode]);

    // Normalize value on switch mode
    useEffect(() => {
        if (mode === 'html') {
            setValue(beautify.html(value));
        }
        if (mode === 'wysiwyg') {
            setValue(value.replace(/\n/g, ''));
        }
    }, [mode]);

    // Watch for initial value change
    useEffect(() => {
        if (!isModified) {
            setValue(initialValue);
        }
    }, [initialValue]);

    // Resize Monaco Editor on window resize
    useEffect(() => {
        const handleResize = () => {
            if (monacoRef.current) {
                monacoRef.current.layout();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Col grow="1">

            {/* Toolbar */}
            <Toolbar onSwitchMode={switchMode} />

            {/* Quill Editor */}
            {
                mode === 'wysiwyg' && (
                    <ReactQuill 
                        theme="snow" 
                        value={value} 
                        onChange={handleQuillEditorChange} 
                        modules={modules}
                        style={{ flexGrow: 1 }}
                    />
                )
            }

            {/* Monaco Editor */}
            {
                true &&mode === 'html' && (
                    <MonacoEditor
                        language="html"
                        // theme="vs-dark"
                        value={value}
                        onChange={handleMonacoEditorChange}
                        // options={{
                        //     automaticLayout: true,
                        // }}
                        onMount={handleMonacoMount}
                    />
                )
            }
        </Col>
    )
};