import { FC } from 'react';
import { LineChart as LineChrt, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getRandomColor } from '../../../../Utils/RandomColor';

type Props = {
    data?: any[];
    lines?: any[];
    width?: number;
    height?: number;
    xAxisKey?: string;
    tooltip?: boolean;
};

// Custom Tooltip
const CustomTooltip = ({ active, payload, label }: any) => {
    const data = payload.length ? payload[0] : null;
    const tooltipData = data ? data?.payload?.tooltip : null;

    if (active && payload && payload.length && tooltipData) {
        const formattedTooltipData = tooltipData.split('\n').map((line: string, index: number) => (
            <p key={index} style={{margin: 0}}>{line}</p>
        ));
        // const { dataKey } = data;
        
        return (
            <div style={{backgroundColor: "#fff", border: `1px solid ${data.color}`, padding: '0.5rem'}}>
                {formattedTooltipData}
            </div>
        );
    }
    return null;
};

// LineChart Component
export const LineChart: FC<Props> = (props: Props) => {

    // Props
    const { data = [], lines = [], width = 500, height = 300, xAxisKey, tooltip = true } = props;
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChrt
                width={width}
                height={height}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {
                    xAxisKey === '' ? null : <XAxis height={100} dataKey={xAxisKey} angle={-45} tick={{ textAnchor: 'end' }}   />
                }
                <YAxis />
                { tooltip === false ? null : <Tooltip content={<CustomTooltip />} /> }
                <Legend verticalAlign="top" />
                {
                    lines.map(({dataKey, color = getRandomColor(), strokeType = 'monotype'}: any) => {
                        return <Line dot={false} key={`${dataKey}-line`} type={strokeType} dataKey={dataKey} stroke={color} />
                    })
                }
            </LineChrt>
        </ResponsiveContainer>
    )
};