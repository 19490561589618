import React from "react";
import { DownloadCellRenderer } from "./DownloadCellRenderer/DownloadCellRenderer.com";

const Renderers: Record<string, React.FC<any>> = {
    'download': DownloadCellRenderer,
};

export const getRenderer = (rendererName: string, params: any = {}) => {    
    const Renderer: React.FC<any> = Renderers[rendererName] as React.FC<any>;
    if (Renderers[rendererName]) {
        return React.createElement(Renderer, {...params});
    }
    return null;
};