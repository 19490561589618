import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../Simple/Icon/Icon.com';
import { Text } from '../../Simple/Text/Text.com';

type Props = {
    data?: any;
    value?: string;
};

// TemplateNameRenderer Component
export const TemplateNameRenderer: FC<Props> = (props: Props) => {
    const { data, value, } = props;
    const { _id, name, name_url } = data;
    
    return (
        <Row>

            {/* Name */}
            <Row shrink="1" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                <Text size="0">{name}</Text>
            </Row>

            {/* Link */}
            {
                !name_url ? null :
                <Row paddingLeft="0.5" paddingRight="0.5" grow="1" justifycontent="end">
                    <Link to={_id} target="_blank" style={{display: 'flex'}}>
                        <Icon name="open" size={18} color="gray" />
                    </Link>
                </Row>
            }
        </Row>
    )
};