import { FC } from 'react';
import { Row } from '../../../../Simple/Layout/Flex/Row/Row.com';
import { Icon } from '../../../../Simple/Icon/Icon.com';

type Props = {
    value: string;
    colDef: any;
};

// HtmlCellRenderer Component
export const HtmlCellRenderer: FC<Props> = (props: Props) => {
    const { value, colDef = {} } = props;
    const { actionButtons = [], emr = '' } = colDef;

    // Handle Icon Click
    const handleIconClick = (action: string, emr: string = '') => {
        const regex = />(.*?)</;
        const match = typeof value === 'number' ? (value as number).toString().match(regex) : value.match(regex);
        const val = match ? match[1] : value;
        
        // Copy to clipboard
        if (action === 'copy') {
            navigator.clipboard.writeText(val);
        }

        //  Open HMS
        if (action === 'openHms') {
            window.open(`https://hms.lavaa.health/patients/${val}`, '_blank');
        }

        //  Open Elation
        if (action === 'openElation' && (emr.toLowerCase() === 'elation' || emr === '')) {
            window.open(`https://app.elationemr.com/patient/${val}`, '_blank');
        } 

        // Open Athena
        if (action === 'openElation' && emr.toLowerCase() === 'athena') {
            window.open(`https://athenanet.athenahealth.com/${val}/75/globalframeset.esp?MAIN=https%3A%2F%2Fathenanet%2Eathenahealth%2Ecom%2F${val}%2F75%2Fax%2Fnon_clinician`, '_blank');
        }
    };
    
    return (
        <Row>
            {
                actionButtons.length === 0 ? null : <Row paddingRight='0.5'>
                    {
                        actionButtons.map((item: any, index: number) => (<Row paddingLeft='0.5'><Icon key={index} name={item.icon} onClick={() => handleIconClick(item.action, emr)} /></Row>))
                    }
                </Row>
            }
            <div dangerouslySetInnerHTML={{ __html: typeof value === 'string' ? value.replace('<!DOCTYPE html>', '') : value }} />
        </Row>
    )
};