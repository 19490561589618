import { createSlice } from '@reduxjs/toolkit';
import { QueriesSliceType } from './Queries.slice.types';

const initialState: QueriesSliceType = {
    queries: []
};

export const QueriesSlice = createSlice({
    name: 'Queries',
    initialState,
    reducers: {

        // Set Queries Data Action
        setQueriesDataAction: (state, action) => {
            const { data } = action.payload;

            state.queries = [...data];
        },

        // Reset Queries Action
        resetQueriesAction: (state) => {
            return {...initialState};
        }
    }
});

export const {
    setQueriesDataAction,
    resetQueriesAction
} = QueriesSlice.actions;
export default QueriesSlice.reducer;