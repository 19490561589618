import { FC } from 'react';
import { ElementProps } from './Element.types';
import classNames from '../../../../Utils/ClassNames';
import css from './Element.module.scss';

type Props = ElementProps;

// Element Component
export const Element: FC<Props> = (props: Props) => {
    const {
        style, className, children, scroll, innerRef, textWrap,
        paddingTop, paddingLeft, paddingRight, paddingBottom,
        marginTop, marginLeft, marginRight, marginBottom,
        onClick, onDoubleClick, onMouseEnter, onMouseLeave, onKeyDown,
        ...rest
    } = props;

    return (
        <div 
            { ...rest }
            ref={ innerRef }
            data-scroll={ scroll }
            data-paddingtop={ paddingTop }
            data-paddingleft={ paddingLeft }
            data-paddingright={ paddingRight }
            data-paddingbottom={ paddingBottom }
            data-margintop={ marginTop }
            data-marginleft={ marginLeft }
            data-marginright={ marginRight }
            data-marginbottom={ marginBottom }
            data-textwrap={ textWrap }
            onClick={ onClick }
            onDoubleClick={ onDoubleClick }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            onKeyDown={onKeyDown}
            className={ classNames(css.Element, className) } 
            style={ {...style} }
        >{children}</div>
    )
};