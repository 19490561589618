import { FC } from 'react';
import classNames from '../../../../Utils/ClassNames';
import { GridProps } from './Grid.types';
import { Element } from '../Element/Element.com';
import css from './Grid.module.scss';

type Props = GridProps;

// Grid Component
export const Grid: FC<Props> = (props: Props) => {
    const { style, className, children, type, template, ...rest } = props;
    const gridTemplateType = type === 'row' ? 'gridTemplateRows' : 'gridTemplateColumns';
    const gridTemplateValue = template || 'repeat(auto-fit, minmax(0, 1fr))';
    
    return (
        <Element 
            {...rest}
            className={classNames(css.Grid, className)} 
            style={{...style, [gridTemplateType]: gridTemplateValue}}
        >{children}</Element>
    )
};