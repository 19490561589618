import { FC, useEffect, useState } from 'react';
import { useQrdaTemplates } from '../../../Hooks/QrdaTemplates/QrdaTemplates.hook';
import { PageTitle } from '../PageTitle/PageTitle.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { DataTable } from '../DataTable/DataTable.com';
import css from './QrdaTemplates.module.scss';

// QrdaTemplates Component
export const QrdaTemplates: FC = () => {

    // Hooks
    const { requestQrdaTemplatesData, downloadQrdaTemplates, qrdaAction } = useQrdaTemplates();

    // States
    const [rows, setRows] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);

    const columnModel = [
        { field: '_id'},
        { field: 'name'},
        { field: 'xml', editable: true, editorType: 'html'},
        { field: 'table_name'},
        { field: 'reporting_year'},
        { field: 'download', renderer: [{name: 'download', props: {downloadCallback: downloadQrdaTemplates}}]},
    ];

    // Did Mount
    useEffect(() => {
        requestQrdaTemplatesData().then((data: any[] = []) => {
            setHeaders(['_id', 'name', 'xml', 'table_name', 'reporting_year', 'download']);
            setRows(data);
        });
    }, []);

    return (
        <Col className={css.PageTemplates} grow="1">
            
            {/* Page Title */}
            <PageTitle title="Qrda Templates" onAction={qrdaAction} />

            {/* Table */}
            <Col paddingLeft="1" paddingRight="1" paddingBottom="1" grow="1">
                <DataTable  
                    headers={headers}
                    rows={rows}
                    columnModel={columnModel}
                />
            </Col>
        </Col>
    )
};