import { useParams } from 'react-router-dom';
import { QrdaTemplatesHookResult } from './QrdaTemplates.hook.types';
import { requestPost } from '../../Utils/Request';
import { API_URL } from '../../Constants/Constants';

export const useQrdaTemplates = (): QrdaTemplatesHookResult => {

    // Hooks
    const { details } = useParams();

    // Request QrdaTemplates Data
    const requestQrdaTemplatesData = () => {
        const params = new URLSearchParams(details);
        const queryName = params.get('queryName');
        const eventType = params.get('eventType');
        const daysBack = params.get('daysBack');
        const body: any = {};

        // Set Body Params
        queryName && (body['query_name'] = queryName);
        eventType && (body['event_type'] = eventType);
        daysBack && (body['days_back'] = daysBack);
        
        // Request QrdaTemplates Data
        return requestPost(`${API_URL}/qrda_templates/get_templates`, {
            body: JSON.stringify(body)
        });
    };

    // Download QrdaTemplate
    const downloadQrdaTemplates = async (name: string) => {
        try {
            const response = await requestPost(`${API_URL}/qrda_templates/download`, {
                template_name: name
            });

            // Check Response
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.zip`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error('Error downloading template:', response.statusText);
            }
        } catch (error: any) {
            console.error('Error:', error?.message);
        }
    };

    // QRDA Action
    const qrdaAction = async () => {
        try {
            await requestPost(`${API_URL}/qrda_templates/action`);
        } catch (error: any) {
            console.error('Error:', error?.message);
        }
    };

    return {
        requestQrdaTemplatesData,
        downloadQrdaTemplates,
        qrdaAction
    };
};