const request = (url: string, options: any = {}) => {
    const defaultOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        ...options
    };

    return fetch(url, defaultOptions)
        .then(res => res.json())
        .then((result) => result.data)
        .catch(err => console.error(err));
};

export const requestGet = (url: string, options: any = {}) => {
    return request(url, {
        ...options
    });
};

export const requestPost = (url: string, options: any = {}) => {
    return request(url, {
        method: 'POST',
        ...options
    });
};