import { FC } from 'react';
import { ResponsiveContainer, PieChart as PieChrt, Pie, Legend, Cell } from 'recharts';
import { getRandomColor } from '../../../../Utils/RandomColor';

export type PieDataType = {
    innerPie?: {
        data: any[];
        dataKey?: string;
        nameKey?: string;
        color?: string;
        tooltip?: boolean;
    };
    outerPie?: {
        data: any[];
        dataKey?: string;
        nameKey?: string;
        color?: string;
        tooltip?: boolean;
    };
};

type Props = {
    data?: PieDataType;
    width?: number;
    height?: number;
    xAxisKey?: string;
    tooltip?: boolean;
};

const RADIAN = Math.PI / 180;

// PieChart Component
export const PieChart: FC<Props> = (props: Props) => {

    // Props
    const { data = {}, width = 500, height = 200, tooltip } = props;
    const { innerPie = { data: [] }, outerPie = { data: []} } = data;
    const { data: innerData = [], dataKey: innerDataKey = 'value', nameKey: innerNameKey = 'name', color: innerColor = getRandomColor(), tooltip: innerTooltip = false } = innerPie;
    const { data: outerData = [], dataKey: outerDataKey = 'value', nameKey: outerNameKey = 'name', color: outerColor = getRandomColor(), tooltip: outerTooltip = false } = outerPie;

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };
    
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChrt width={width} height={height}>

                {/* Inner Pie */}
                {
                    !innerPie?.data ? null : 
                    <Pie data={innerData} dataKey={innerDataKey} nameKey={innerNameKey} cx="50%" cy="50%" outerRadius={60} fill={innerColor} label={renderCustomizedLabel} labelLine={false} >
                        {
                            innerData.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={entry.color || getRandomColor()} />
                            ))
                        }
                    </Pie>
                }

                {/* Outer Pie */}
                {
                    !outerPie?.data ? null : 
                    <Pie data={outerData} dataKey={outerDataKey} nameKey={outerNameKey} cx="50%" cy="50%" innerRadius={70} outerRadius={90} fill={outerColor} label={outerTooltip}>
                        {
                            outerData.map((entry: any, index: number) => (
                                <Cell key={`cell-${index}`} fill={entry.color || getRandomColor()} />
                            ))
                        }
                    </Pie>
                }
                <Legend verticalAlign="bottom" height={36}/>
            </PieChrt>
        </ResponsiveContainer>
    )
};